import {
  __,
  addresses as aService,
  buyerWorkspaceActions,
  countrySelectors,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMAGES } from '../../../assets';
import RadioInput from '../../atoms/RadioInput';
import ActionsModal from '../ActionsModal/ActionsModal.component';
import AddressAutoComplete from '../AddressAutoComplete/AddressAutoComplete.component';
import EmptyListResource from '../EmptyListResource/EmptyListResource.component';
import * as S from './AddressModal.styled';

interface IProps {
  address?: IAddress;
  addresses?: Array<IAddress>;
  catalogId?: number;
  client?: IClient;
  disabledAddresses?: Array<number>;
  createAddress?: () => void;
  isBuyerAddress?: boolean;
  onBack?: () => void;
  onClose: () => void;
  onSelectAddress: (a: IAddress | Array<IAddress>) => void;
  subtitle: string;
  title: string;
  withMultipleSelection?: boolean;
}

/**
 * Render the modal with the all addresses for select
 */
const AddressModal: React.FC<IProps> = ({
  address,
  addresses,
  catalogId,
  client,
  disabledAddresses,
  createAddress,
  isBuyerAddress,
  onBack,
  onClose,
  onSelectAddress,
  subtitle,
  title,
  withMultipleSelection,
}) => {
  const countries = useSelector(countrySelectors.getCountries);
  const [showNewAddressModal, setShowNewAddressModal] = React.useState(false);
  const [filter, setFilter] = React.useState<string>('');
  const [selectedAddresses, setSelectedAddresses] = React.useState<Array<IAddress>>([]);

  if (showNewAddressModal)
    return (
      <NewAddressModal
        catalogId={catalogId}
        countries={countries}
        onClose={onClose}
        client={client}
        onAddressCreated={(adr: IAddress) => {
          if (adr) onSelectAddress([adr]);
        }}
      />
    );

  return (
    <ActionsModal title={title} subtitle={subtitle} onClose={onClose} onBack={onBack}>
      {createAddress ? (
        <S.AddMoreCta iconName={'Add-more'} type="link" onClick={() => createAddress()} withoutPadding>
          {__('Components.Settings.address.EmptyResults.cta')}
        </S.AddMoreCta>
      ) : null}
      <S.Search
        onChange={s => setFilter(s)}
        placeHolder={__('ProductListShare.search_placeholder')}
        id="input_search_product_share"
      />
      {addresses?.length ? (
        addresses
          .filter(
            a => !filter || aService.getAddressName(a, countries, true).toLowerCase().includes(filter.toLowerCase()),
          )
          .sort((a, b) => {
            if (a.displayName > b.displayName) {
              return 1;
            }
            if (b.displayName > a.displayName) {
              return -1;
            }
            return 0;
          })
          .map((a, idx) => {
            const isDisabled = disabledAddresses?.includes(a?.id || 0);
            return (
              <S.AddressItem
                key={a.id + '_' + idx}
                onClick={() => {
                  if (isDisabled) return;
                  if (withMultipleSelection) {
                    const isAlreadySelected = selectedAddresses.some(ad => ad.id === a.id);
                    setSelectedAddresses(
                      isAlreadySelected
                        ? selectedAddresses.filter(ad => ad.id !== a.id) // Remove if already selected
                        : [...selectedAddresses, a], // Add if not selected
                    );
                    return;
                  }
                  // multiple selection disabled
                  onSelectAddress(a);
                }}
              >
                {withMultipleSelection ? (
                  <RadioInput
                    className="add-address-checkbox"
                    checked={!!selectedAddresses.find(ad => ad.id === a.id)?.id}
                    value=""
                    isRadio={false}
                    disabled={false}
                  />
                ) : (
                  <RadioInput
                    checked={a.id === (address || addresses.find(ad => ad.transportCost))?.id}
                    value=""
                    isRadio={true}
                    disabled={isDisabled}
                  />
                )}
                <S.AddressIconContainer>
                  <S.AddressIcon name="Address" disableHover={true} />
                </S.AddressIconContainer>
                <S.ItemContainer isDisabled={isDisabled}>
                  <S.ItemTitle isDisabled={isDisabled}>
                    {aService.getAddressName(a, countries, true)}
                    {isDisabled ? (
                      <S.ItemTitleDisabled>{' ' + __('AddressModal.missing_external_id')}</S.ItemTitleDisabled>
                    ) : null}
                  </S.ItemTitle>
                  <S.AddressText isDisabled={isDisabled}>{aService.getAddressName(a, countries, false)}</S.AddressText>
                </S.ItemContainer>
              </S.AddressItem>
            );
          })
      ) : (
        <S.EmptyContainer>
          <EmptyListResource
            imageUrl={IMAGES.informativePineapple.replace('f_auto', 'f_auto,c_scale,w_260')}
            showButton={isBuyerAddress ? false : !!catalogId}
            buttonText={__('AddressModal.empty.cta')}
            text2={client || isBuyerAddress ? __('AddressModal.empty.title_client') : __('AddressModal.empty.title')}
            buttonAction={() => setShowNewAddressModal(true)}
          />
        </S.EmptyContainer>
      )}
      {selectedAddresses.length && withMultipleSelection ? (
        <S.AddressCta
          onClick={() => {
            onSelectAddress(selectedAddresses);
          }}
        >
          {__('AddressModal.cta')}
        </S.AddressCta>
      ) : null}
    </ActionsModal>
  );
};

const NewAddressModal = ({
  countries,
  catalogId,
  client,
  onAddressCreated,
  onClose,
}: {
  catalogId: number;
  countries: Record<string, ICountry>;
  client?: IClient;
  onAddressCreated: (a: IAddress) => void;
  onClose: () => void;
}) => {
  const me = useSelector(userSelectors.getUser);
  const catalog = useSelector(sellerWorkspaceSelectors.getCatalog(catalogId));
  const dispatch = useDispatch<any>();
  const addressComponentProps = {
    countries,
    isDefault: true,
    me,
    onCancel: onClose,
    onSubmit: (adr: IAddress) => {
      if (client) {
        dispatch(
          sellerWorkspaceActions.clientAddressAdd(me.id, catalogId, client.userId, adr, err => {
            if (!err) onAddressCreated(adr);
          }),
        );
      } else {
        dispatch(
          buyerWorkspaceActions.workspaceAddressAdd(me.id, catalogId, adr, err => {
            if (!err) onAddressCreated(adr);
          }),
        );
      }
      onClose();
    },
    tradeCurrency: client?.currency,
    weightUnit: catalog?.defaultWeightUnit,
  };
  return (
    <ActionsModal onClose={onClose} title={__('Components.AddNewAddress.title')}>
      <AddressAutoComplete {...addressComponentProps} />
    </ActionsModal>
  );
};

export default React.memo(AddressModal);
