import { __ } from 'common-services';
import * as React from 'react';

import { EmptyListResource, OrderDelivery } from '../../molecules';
import * as S from './OrdersList.styled';
import { OrdersContent } from './OrdersListOrders.component';
import { RequestsContent } from './OrdersListRequest.component';

interface TableContentProps {
  viewMode: 'delivery' | 'order' | 'request';
  orderItems: Array<IOrderAggregationItem>;
  contacts: { [cId: number]: IContact };
  myId: number;
  search: ISearchOrder;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  orders: Array<IOrder>;
  orderRequests: Array<IOrderRequest>;
  type: 'purchase' | 'sale';
  contactId?: number;
  setShowContactsModal: (show: boolean) => void;
  catalogHash?: string;
  catalogs: { [id: number]: IWorkspace };
  clients: { [id: number]: Array<IClient> };
  dateRange: string;
  hasMore: boolean;
  isUnselected: boolean;
  me: IUser;
  openOrderSelected: (order: IOrder) => void;
  selectedOrderIds: Array<number>;
  setSelectedOrderIds: (arr: Array<number>) => void;
  orderToggleReadStatus: (userId: number, orderId: number, status: boolean) => void;
  removeSelection: () => void;
  selectAll: () => void;
  suppliers: { [id: number]: Array<ISupplier> };
  totalResults: number;
  tabSelected: string;
  facets: IFacets;
  globalFacets: IFacets;
  openOrderRequestSelected: (request: IOrderRequest) => void;
  setShowShare: (show: boolean) => void;
  markAllAsRead: () => void;
  updateSortMode?: (sortBy?: string, sortOrder?: 'desc' | 'asc') => void;
}

const TableContentComponent: React.FC<TableContentProps> = ({
  viewMode,
  orderItems,
  contacts,
  myId,
  search,
  touchImage,
  orders,
  orderRequests,
  type,
  contactId,
  setShowContactsModal,
  catalogHash,
  catalogs,
  clients,
  dateRange,
  hasMore,
  isUnselected,
  me,
  openOrderSelected,
  selectedOrderIds,
  setSelectedOrderIds,
  orderToggleReadStatus,
  removeSelection,
  selectAll,
  suppliers,
  totalResults,
  tabSelected,
  facets,
  globalFacets,
  openOrderRequestSelected,
  setShowShare,
  markAllAsRead,
  updateSortMode,
}) => {
  switch (viewMode) {
    case 'delivery':
      return orderItems.length ? (
        <OrderDelivery
          contacts={contacts}
          myId={myId}
          orderItems={orderItems}
          searchState={search}
          touchImage={touchImage}
        />
      ) : (
        <S.CenterContainer className="orders-list-center-container">
          <EmptyListResource text={__('Components.OrdersList.EmptyResults.title_delivery')} showButton={false} />
        </S.CenterContainer>
      );
    case 'order':
      return (
        <OrdersContent
          orders={orders}
          search={search}
          type={type}
          contactId={contactId}
          setShowContactsModal={setShowContactsModal}
          catalogHash={catalogHash}
          catalogs={catalogs}
          clients={clients}
          contacts={contacts}
          dateRange={dateRange}
          hasMore={hasMore}
          isUnselected={isUnselected}
          me={me}
          openOrderSelected={openOrderSelected}
          selectedOrderIds={selectedOrderIds}
          setSelectedOrderIds={setSelectedOrderIds}
          orderToggleReadStatus={orderToggleReadStatus}
          removeSelection={removeSelection}
          selectAll={selectAll}
          suppliers={suppliers}
          totalResults={totalResults}
          touchImage={touchImage}
          tabSelected={tabSelected}
          facets={facets}
          globalFacets={globalFacets}
          markAllAsRead={markAllAsRead}
          updateSortMode={updateSortMode}
        />
      );
    case 'request':
      return (
        <RequestsContent
          orderRequests={orderRequests}
          catalogs={catalogs}
          contacts={contacts}
          hasMore={hasMore}
          me={me}
          openOrderRequestSelected={openOrderRequestSelected}
          catalogHash={catalogHash}
          totalResults={totalResults}
          touchImage={touchImage}
          setShowShare={setShowShare}
        />
      );
    default:
      return null;
  }
};

export const TableContent = React.memo(TableContentComponent);
