import { CURRENCY_CODES, date, EventTrack, IDateRange, qs, RenderTrack, sellerWorkspaceService } from 'common-services';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getOrderWidgetDescriptions, ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { Facets } from '../../molecules';
import { DashboardHeader } from './DashboardHeader.component';
import { DashboardWidgets } from './DashboardWidgets.component';
import { NewCart } from './NewCart.component';
import { OrderRequestDetailsWrapper } from './OrderRequestDetailsWrapper.component';
import { IDispatchProps, IOwnProps, IRouteProps, IStateProps, TABS } from './OrdersList.constants';
import { useDateSetter } from './OrdersList.dates';
import { useInitialFilters } from './OrdersList.filters';
import { useScrollSearch, useSearchState, useSendSearch } from './OrdersList.search';
import * as S from './OrdersList.styled';
import { TableContent } from './OrdersListTable.component';
import { SelectClientOrderModal } from './SelectClientOrderModal.component';
import { ShareModal } from './ShareModal.component';

type IProps = IStateProps & IDispatchProps & IOwnProps & IRouteProps;

export const OrdersList: React.FC<IProps> = props => {
  const history = useHistory();
  const params = useParams<{ workspaceId?: string; channelId?: string; contactId?: string }>();

  const lastDatesUsedRef = React.useRef<{ beginDate: Date; endDate: Date }>(null);

  const initialFilters = useInitialFilters(
    props.filters,
    props.fromChat,
    props.amSeller,
    props.catalogs,
    props.me.sellerWorkspaceId!,
  );

  const searchState = useSearchState({
    viewMode: props.viewMode || 'order',
    type: props.type,
    me: props.me,
    catalogHash: props.catalogHash,
    catalogs: props.catalogs,
    contactId: props.contactId,
    onlyUnreads: props.onlyUnreads,
  });

  const isPurchase = () => {
    return (props.type && props.type === 'purchase') || orderSelected?.buyerId === props.me.id;
  };

  const workspaceSelected = props.workspaces[props.me.sellerWorkspaceId!];

  const [facets, setFacets] = React.useState<IFacets>(props.facets);
  const [globalFacets, setGlobalFacets] = React.useState<IFacets>(props.facetsGlobal);
  const [search, setSearch] = React.useState<ISearchOrder>(searchState);

  const [dateRange, setDateRange] = React.useState<IDateRange>(initialFilters.dateRange);
  const [dateRangeDeliveryDate, setDateRangeDeliveryDate] = React.useState<IDateRange>(initialFilters.dateRange);

  const [hasMore, setHasMore] = React.useState<boolean>(props.hasMore);
  const [orderItems, setOrderItems] = React.useState<Array<IOrderAggregationItem>>([]);
  const [orderRequests, setOrderRequests] = React.useState<Array<IOrderRequest>>([]);
  const [hideFilters, setHideFilters] = React.useState<boolean>(true);
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [isUnselected, setIsUnselected] = React.useState<boolean>(initialFilters.isUnselect);
  const [selectedOrderRequest, setSelectedOrderRequest] = React.useState<IOrderRequest>(undefined);
  const [selectedOrderIds, setSelectedOrderIds] = React.useState<Array<number>>(
    initialFilters.selected ? initialFilters.selected.split(',').map(id => Number(id)) : [],
  );
  const [totalResults, setTotalResults] = React.useState<number>(props.totalResults);
  const [viewMode, setViewMode] = React.useState<'order' | 'delivery' | 'request'>(props.viewMode || 'order');
  const [searchId, setSearchId] = React.useState<string>(props.searchId);
  const [showContactsModal, setShowContactsModal] = React.useState<boolean>(false);
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const [tabSelected, setTabSelected] = React.useState<string>(props.tabSelected);
  const [orderSelected, setOrderSelected] = React.useState<IOrder>(props.orderSelected);

  const [dashboardDates, setDashboardDates] = React.useState<{ beginDate: Date | null; endDate: Date | null }>({
    beginDate: null,
    endDate: null,
  });
  React.useEffect(() => {
    if (dateRange === 'custom') {
      setDashboardDates({ beginDate: search.beginDate, endDate: search.endDate });
    } else {
      setDashboardDates(date.getDatesFromRange(dateRange));
    }
  }, [dateRange, search]);

  // Props reactivity. Seemed mandatory because props are not set when the component is mounted
  React.useEffect(() => {
    if (
      !orderSelected ||
      !props.orderSelected ||
      (props.orderSelected && orderSelected && props.orderSelected.id !== orderSelected.id)
    ) {
      setOrderSelected(props.orderSelected);
    }
  }, [props.orderSelected]);

  React.useEffect(() => {
    if (props.facets) {
      setFacets(props.facets);
    }
  }, [props.facets]);

  React.useEffect(() => {
    if (props.totalResults) {
      setTotalResults(props.totalResults);
    }
  }, [props.totalResults]);

  React.useEffect(() => {
    if (props.facetsGlobal) {
      setGlobalFacets(props.facetsGlobal);
    }
  }, [props.facetsGlobal]);

  React.useEffect(() => {
    if (props.searchId) {
      setSearchId(props.searchId);
    }
  }, [props.searchId]);

  React.useEffect(() => {
    if (props.viewMode) {
      setViewMode(props.viewMode);
    }
  }, [props.viewMode]);

  React.useEffect(() => {
    if (props.tabSelected) {
      setTabSelected(props.tabSelected);
    }
  }, [props.tabSelected]);

  React.useEffect(() => {
    if (props.type) {
      setSearch(searchState);
    }
  }, [props.type]);

  React.useEffect(() => {
    if (props.catalogHash) {
      setSearch(searchState);
    }
  }, [props.catalogHash]);

  // End of props reactivity

  // Send search
  const sendSearch = useSendSearch({
    viewMode,
    search,
    meId: props.me.id,
    orderSearch: props.orderSearch,
    setIsSearched,
    setFacets,
    setGlobalFacets,
    setOrderRequests,
    setOrderItems,
    setSearchId,
    setTotalResults,
  });

  // Scroll search
  const scrollSearch = useScrollSearch({
    viewMode,
    searchId,
    orderScrollSearch: props.orderScrollSearch,
    setOrderRequests,
    setOrderItems,
  });

  // Initial search
  React.useEffect(() => {
    RenderTrack.track('OrderList', {
      renderTime: Date.now(),
      type: props.type,
      tab: tabSelected,
      view_mode: viewMode,
    });

    lastDatesUsedRef.current = dashboardDates;
    const catalog = props.catalogHash
      ? Object.values(props.catalogs).find(c => c.hashId === props.catalogHash)
      : props.catalogs[props.me.sellerWorkspaceId];

    if (
      ((catalog && sellerWorkspaceService.isProPlan(catalog.plan)) || isPurchase()) &&
      tabSelected !== TABS.UNREAD &&
      !props.fromChat
    ) {
      const { workspaceId } = params;
      if (!props.fromChat) {
        props.ordersDashboardWorkspaceGet(
          Number(workspaceId),
          props.type === 'purchase' ? 'buyer' : 'seller',
          dashboardDates,
          search.currency,
          getOrderWidgetDescriptions(props.type as IOrderType, dashboardDates),
          props.contactId,
        );
      } else if (!props.catalogHash) {
        props.ordersDashboardGet(
          props.me.id!,
          dashboardDates,
          search.currency,
          getOrderWidgetDescriptions(props.type as IOrderType, dashboardDates),
          props.contactId,
        );
      }
    }
    if (props.hashId) {
      showOrderFromUrl();
    } else {
      sendSearch();
    }
  }, [dashboardDates]);

  // Update the url params when the search state changes
  React.useEffect(() => {
    if (props.tabSelected && props.tabSelected !== tabSelected) {
      props.updateUrl?.(search, dateRange, props.hashId ? { hashId: props.hashId } : undefined);
    }
  }, [search, dateRange, props.tabSelected, props.hashId]);

  // Handle dates changes to update the lastDatesUsedRef
  React.useEffect(() => {
    if (
      search.beginDate &&
      search.endDate &&
      (search.beginDate !== lastDatesUsedRef.current.beginDate || search.endDate !== lastDatesUsedRef.current.endDate)
    ) {
      lastDatesUsedRef.current = { beginDate: search.beginDate, endDate: search.endDate };
    }
  }, [search.beginDate, search.endDate]);

  // Handle search changes to send the search
  React.useEffect(() => {
    if (!window.location.href.includes('sell')) {
      lastDatesUsedRef.current = { beginDate: search.beginDate, endDate: search.endDate };
      sendSearch();
    }
  }, [search, sendSearch]);

  // Handle facets changes to get the clients and suppliers
  React.useEffect(() => {
    if (facets) {
      const catalogHashes = Object.keys(facets.catalogs || {});
      catalogHashes.forEach(ch => {
        const catalogId = Object.values(props.catalogs).find(c => c.hashId === ch)?.id;
        const workspaceId = Object.values(props.workspaces).find(c => c.hashId === ch)?.id;
        if (catalogId && !(props.clients || {})[catalogId]) props.clientsGet(props.me.id!, catalogId);
        if (workspaceId && !(props.suppliers || {})[workspaceId]) props.suppliersGet(props.me.id!, workspaceId);
      });
    }
  }, [facets, props.catalogs, props.workspaces, props.clients, props.suppliers, props.me.id]);

  // Handle currency changes to send a new search if the currency is not available
  React.useEffect(() => {
    if (
      facets &&
      facets.currency &&
      Object.keys(facets.currency).length &&
      search &&
      search.currency &&
      !facets.currency[search.currency] &&
      tabSelected !== TABS.UNREAD
    ) {
      setSearch(prevState => ({
        ...prevState,
        currency: Object.keys(facets.currency)[0] as CURRENCY_CODES,
      }));
    }
  }, [facets.currency, search.currency]);

  // Handle searchId changes to show the order from the url
  React.useEffect(() => {
    if (searchId) {
      setIsSearched(true);
      showOrderFromUrl(orderSelected);
    }
  }, [searchId, orderSelected]);

  // Handle orders, contacts, or hashId changes to show the order from the url
  React.useEffect(() => {
    if (Object.keys(props.contacts).length) {
      showOrderFromUrl(orderSelected);
    }
  }, [props.contacts, orderSelected]);

  // Handle tabSelected changes to update the filters
  React.useEffect(() => {
    return () => {
      if (tabSelected !== TABS.UNREAD) {
        props.orderFiltersSet(0, {
          ...props.filters,
          currency: search.currency,
          range: dateRange,
          rangeDeliverDate: dateRangeDeliveryDate,
          since: lastDatesUsedRef.current.beginDate ? lastDatesUsedRef.current.beginDate.getTime() : undefined,
          type: props.type,
          until: lastDatesUsedRef.current.endDate ? lastDatesUsedRef.current.endDate.getTime() : undefined,
        });
      }
    };
  }, []);

  // Handle the selection of the dates
  const setDates = useDateSetter({
    search,
    viewMode,
    setDateRange,
    setSearch,
    lastDatesUsedRef,
  });
  // const setDatesDeliveryDate = useDateSetter({
  //   search,
  //   viewMode,
  //   setDateRange: setDateRangeDeliveryDate,
  //   setSearch,
  //   // lastDatesUsedRef,
  // });

  // Handle the scroll event to load more orders
  const onScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (
        props.hasMore &&
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 500
      ) {
        scrollSearch();
      }
    },
    [props.hasMore, scrollSearch],
  );

  // Show the order from the url
  const showOrderFromUrl = React.useCallback(
    (orderSelected?: IOrder) => {
      if (props.hashId && (!orderSelected || orderSelected.hashId !== props.hashId)) {
        const order =
          props.hashId === 'new'
            ? props.orders.find(or => or.id === 0)
            : props.orders.find(or => or.hashId === props.hashId);
        if (order && (!orderSelected || !order.id || orderSelected.id === order.id)) {
          openOrderSelected(order);
        } else if (props.hashId !== 'new') {
          props.orderGetByHash(props.me.id, props.hashId, (o?: IOrder, err?: Error) => {
            if (!err) openOrderSelected(o);
          });
        }
      }
    },
    [props.hashId, props.orders, props.me.id, props.orderGetByHash],
  );

  const goToAggregateList = () => {
    const url =
      getPath({
        path:
          props.type === 'sale'
            ? params.workspaceId
              ? ROUTE_PATHS.WORKSPACE_SALES_AGGREGATE
              : search.catalogHash
              ? ROUTE_PATHS.SALES_AGGREGATE_WORKSPACE
              : ROUTE_PATHS.SALES_AGGREGATE
            : params.workspaceId
            ? ROUTE_PATHS.WORKSPACE_PURCHASES_AGGREGATE
            : search.buyerWsHash
            ? ROUTE_PATHS.PURCHASES_AGGREGATE_WORKSPACE
            : ROUTE_PATHS.PURCHASES_AGGREGATE,
        orderSelectedIds: [],
        workspaceId: params.workspaceId,
        workspaceHash: props.type === 'sale' ? search.catalogHash : search.buyerWsHash,
      }) +
      qs.stringify({
        since: search.beginDate && search.beginDate.getTime(),
        until: search.endDate && search.endDate.getTime(),
        currency: search.currency,
        type: props.type,
        // selected: isUnselected ? [] : selectedOrderIds.join(','),
        selected: selectedOrderIds.join(','),
        // unselected: isUnselected ? selectedOrderIds.join(',') : [],
        unselected: [],
        only_unreads: search.onlyUnreads,
      });
    history.push(url);
  };

  const removeSelection = () => {
    setSelectedOrderIds([]);
    setIsUnselected(false);
  };

  const selectAll = () => {
    const orderIds = props.orders.map(order => order.id);
    setSelectedOrderIds(orderIds);
    setIsUnselected(true);
  };

  const openOrderSelected = (orderSelected: IOrder) => {
    setHideFilters(true);
    props.updateUrl?.(search, dateRange, orderSelected);
  };

  const openOrderRequestSelected = (orderRequestSelected: IOrderRequest) => {
    setSelectedOrderRequest(orderRequestSelected);
  };

  const closeOrderSelected = () => {
    if (props.fromChat) {
      if (props.fromMessages) {
        history.push(
          getPath({
            path: ROUTE_PATHS.CONTACT,
            channelId: params.channelId,
          }),
        );
      } else {
        history.push(
          getPath({
            path: ROUTE_PATHS.CONTACT_ORDERS,
            channelId: params.channelId,
          }),
        );
      }
    } else {
      props.updateUrl?.(search, undefined);
    }
  };

  const getFiltersCount = () => {
    if (tabSelected === 'unreads') return 0;
    const {
      beginDate,
      catalogHashs,
      category,
      currency,
      endDate,
      origin,
      buyers,
      sellers,
      shippingAddress,
      sections,
      status,
      type: searchType,
    } = search;
    let result = 0;
    if (beginDate || endDate) result++;
    if (viewMode !== 'request' && currency) result++;
    if (viewMode === 'delivery') {
      result =
        result +
        (origin?.length || 0) +
        (shippingAddress?.length || 0) +
        (sections?.length || 0) +
        (searchType?.length || 0) +
        (category?.length || 0);
    }
    if (['order', 'request'].includes(viewMode)) {
      result += (status?.length || 0) + (origin?.length || 0) + (sections?.length || 0) + (catalogHashs?.length || 0);
      if (!props.fromChat) {
        result += (sellers?.length || 0) + (buyers?.length || 0);
      } else if (!props.contactId) {
        result += isPurchase() ? buyers?.length || 0 : sellers?.length || 0;
      }
    }
    return result;
  };

  return (
    <>
      <S.OrdersContainerRow className="orders-list-orders-container-row">
        {tabSelected !== TABS.UNREAD || viewMode !== 'order' ? (
          <Facets
            showDeliveryRangeFilter={true}
            catalogs={props.catalogs}
            changeSearchState={onChangeSearchState()}
            clearFilters={() => {
              setDateRange('all');
              setDateRangeDeliveryDate('all');
              onChangeSearchState()(searchState, 'all', 'all');
              setSearch(searchState);
              EventTrack.track('orders_filter_clear', { tab: tabSelected, type: props.type, mode: viewMode });
            }}
            clients={props.clients[params.workspaceId || '']}
            contacts={props.contacts}
            dateRange={dateRange}
            dateRangeDeliveryDate={dateRangeDeliveryDate}
            facets={facets}
            facetsGlobal={globalFacets}
            filterByDeliveryAt={viewMode === 'delivery'}
            me={props.me}
            numHeaders={1}
            onHide={hide => setHideFilters(hide)}
            searchState={search}
            showAll={true}
            showClosed={hideFilters}
            type={props.type}
            viewMode={viewMode}
            filtersBlacklist={
              props.fromChat
                ? ['buyers', 'sellers']
                : props.contactId
                ? props.type === 'sale'
                  ? ['buyers']
                  : ['sellers']
                : undefined
            }
          />
        ) : null}
        <S.Container className="orders-dashboard-container">
          <DashboardHeader
            tabSelected={tabSelected}
            hideFilters={hideFilters}
            setHideFilters={setHideFilters}
            viewMode={viewMode}
            type={props.type}
            fromChat={props.fromChat}
            contactId={props.contactId}
            contacts={props.contacts}
            me={props.me}
            selectedOrderIds={selectedOrderIds}
            isUnselected={isUnselected}
            setSearch={setSearch}
            setViewMode={setViewMode}
            getFiltersCount={getFiltersCount}
            goToAggregateList={goToAggregateList}
          />

          <S.ScrollContainer className="orders-dashboard-scroll-container" onScroll={onScroll}>
            {props.fromChat || props.onlyUnreads || ['delivery', 'request'].includes(viewMode) || false ? null : (
              <S.SubHeaderDashboard className="orders-dashboard-subheader">
                <DashboardWidgets
                  orders={props.orders}
                  catalogHash={props.catalogHash}
                  catalogs={props.catalogs}
                  me={props.me}
                  type={props.type}
                  modalOpen={props.modalOpen}
                  requestProInfo={props.requestProInfo}
                  dashboard={props.dashboard}
                  dateRange={dateRange}
                  search={search}
                  tabSelected={tabSelected}
                  contacts={props.contacts}
                />
              </S.SubHeaderDashboard>
            )}
            <TableContent
              catalogHash={props.catalogHash}
              catalogs={props.catalogs}
              clients={props.clients}
              contactId={props.contactId}
              contacts={props.contacts}
              dateRange={dateRange}
              facets={props.facets}
              globalFacets={props.facetsGlobal}
              hasMore={props.hasMore}
              isUnselected={isUnselected}
              markAllAsRead={() => props.ordersAllMarkAsRead(props.me.id!, 0, props.type)}
              me={props.me}
              myId={props.me.id}
              openOrderRequestSelected={openOrderRequestSelected}
              openOrderSelected={openOrderSelected}
              orderItems={orderItems}
              orderRequests={orderRequests}
              orders={props.orders}
              orderToggleReadStatus={props.orderToggleReadStatus}
              removeSelection={removeSelection}
              search={search}
              selectAll={selectAll}
              selectedOrderIds={selectedOrderIds}
              setSelectedOrderIds={setSelectedOrderIds}
              setShowContactsModal={setShowContactsModal}
              setShowShare={setShowShare}
              suppliers={props.suppliers}
              tabSelected={tabSelected}
              totalResults={totalResults}
              touchImage={props.touchImage}
              type={props.type}
              updateSortMode={(sort, sortOrder) => {
                setSearch({ ...searchState, sort, sortOrder });
              }}
              viewMode={viewMode}
            />
          </S.ScrollContainer>
        </S.Container>
      </S.OrdersContainerRow>
      {orderSelected ? (
        <NewCart
          orderSelected={orderSelected}
          contacts={props.contacts}
          isPurchase={isPurchase}
          numberOfHeaders={props.numberOfHeaders || 0}
          hideBack={props.hideBack || false}
          fromMessages={props.fromMessages}
          updateUrl={props.updateUrl}
          search={search}
          dateRange={dateRange}
          closeOrderSelected={closeOrderSelected}
          breadcrumb={props.breadcrumb}
          updateBreadcrumb={props.updateBreadcrumb}
          sendSearch={sendSearch}
          me={props.me}
          type={props.type}
          catalogs={props.catalogs}
          workspaces={props.workspaces}
        />
      ) : null}
      {selectedOrderRequest ? (
        <OrderRequestDetailsWrapper
          selectedOrderRequest={selectedOrderRequest}
          setSelectedOrderRequest={setSelectedOrderRequest}
          workspaceSelected={workspaceSelected}
        />
      ) : null}
      {showShare ? (
        <ShareModal
          amSeller={props.amSeller}
          ownerId={props.me.id}
          catalogId={props.me.sellerWorkspaceId!}
          showShare={showShare}
          setShowShare={setShowShare}
        />
      ) : null}
      {showContactsModal ? (
        <SelectClientOrderModal
          isPurchase={isPurchase()}
          contacts={props.contacts}
          me={props.me}
          type={props.type}
          catalogHash={props.catalogHash}
          modalOpen={props.modalOpen}
          createNewContact={props.createNewContact}
          contactUpdateMySellerWorkspace={props.contactUpdateMySellerWorkspace}
          notificationShow={props.notificationShow}
          onClose={() => setShowContactsModal(false)}
        />
      ) : null}
    </>
  );

  function onChangeSearchState(): (
    s: ISearchOrder,
    dateRange?: globalThis.IDateRange,
    dateRangeDeliveryDate?: globalThis.IDateRange,
  ) => void {
    return (s, range, rangeDeliverDate) => {
      setSearch(s);
      if (range) {
        setDates(range, false);
        setSearch({
          ...s,
          beginDate: s.beginDate,
          endDate: s.endDate,
          endDeliveryDate: s.endDeliveryDate,
          beginDeliveryDate: s.beginDeliveryDate,
        });
      }
      if (rangeDeliverDate) {
        if (rangeDeliverDate === 'unknown') {
          s.beginDeliveryDate = null;
          s.endDeliveryDate = null;
        }
        // setDatesDeliveryDate(rangeDeliverDate, false);
        setDateRangeDeliveryDate(rangeDeliverDate === 'unknown' ? 'unknown' : rangeDeliverDate);
      }
    };
  }
};
