import { __, debounce, EventTrack } from 'common-services';
import * as S from './OrderMagicImport.styled';
import * as React from 'react';

export const trackFormatSearch = debounce((contact: IContact, search: string) => {
  if (search)
    EventTrack.track('import_orders_format_search', {
      contact_id: contact?.id,
      search,
    });
}, 500);

export const formatTextColor = (text: string, color: string, action?: () => void) => {
  return text.split('**').map((s, index) =>
    index % 2 ? (
      <S.TextColor key={s + index} color={color} onClick={action}>
        {s}
      </S.TextColor>
    ) : (
      s
    ),
  );
};
