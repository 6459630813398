import styled, { ITheme } from '../../../styled-components';
import { ColumnContainer, FontIcon, RowContainer } from '../../atoms';

interface IStyledProps {
  theme: ITheme;
}
export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${(p: IStyledProps) => `calc(${p.theme.contentHeightWithBreadcrumb} - 2 * ${p.theme.headerHeight})`};
`;

export const Filters = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey1};
  padding-left: ${(p: IStyledProps) => p.theme.paddingObject};
  display: flex;
  align-self: center;
`;

export const HeaderRow = styled(RowContainer)`
  align-items: center;
  justify-content: space-between;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-bottom: 0;
  width: 100%;
`;

export const FiltersTitle = styled(Filters)`
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;

export const Container = styled(ColumnContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  align-items: flex-start;
  position: relative;
  flex: 1;
`;

export const Back = styled.span`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  cursor: pointer;
  display: flex;
  &:hover {
    opacity: 0.75;
  }
`;

export const ChevronIcon = styled(FontIcon)`
  margin-right: 5px;
`;

export const BackContainer = styled(RowContainer)`
  background: ${(p: IStyledProps) => p.theme.colors.white};
  min-height: ${(p: IStyledProps) => p.theme.headerHeight};
  max-height: ${(p: IStyledProps) => p.theme.headerHeight};
  background-color: ${(p: IStyledProps) => p.theme.colors.grey4};
  border-bottom: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  align-items: center;
`;

export const CartContainer = styled(RowContainer)`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-start;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 2;
`;

export const ShowroomContainer = styled(ColumnContainer)`
  overflow: hidden;
  flex: 1;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const ShowroomContainerRow = styled(RowContainer)`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;
