import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, DatePicker as DP, FontIcon, RowContainer, TagBubble, TextLabel } from '../../atoms';
import SimpleSearchComponent from '../../molecules/SimpleSearch/SimpleSearch.component';

interface IStyledProps {
  disableHover?: boolean;
  hiddenAtScreenWidth?: string;
  isSearch?: boolean;
  overlay?: boolean;
  selected?: boolean;
  theme: ITheme;
}

export const WsWrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  flex: 1;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Row = styled(RowContainer)`
  flex: 1;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow: hidden;
`;

export const CenterContainer = styled(ColumnContainer)`
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const StatusTag = styled(TagBubble)`
  width: 70px;
  max-width: 80px;
  justify-content: center;
  align-items: center;
`;

const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const HeaderDashboards = styled(ColumnContainer)`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const HeaderFilters = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const LeftFiltersRow = styled(RowContainer)`
  align-items: center;
`;

export const SimpleSearch = styled(SimpleSearchComponent)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  min-width: 235px;
  max-width: 492px;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    min-width: 185px;
  }
`;

export const RightCtaRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

//// NEW

export const HeaderRow = styled(RowContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderRow = styled(RowContainer)`
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(4)};
`;

export const LeftRow = styled(RowContainer)`
  align-items: center;
`;

export const InfoRow = styled(RowContainer)`
  width: 100%;
  justify-content: start;
  align-items: center;
  margin: 0;
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(0)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const FieldText = styled(Text)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const ClientNameColumn = styled(ColumnContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
  min-width: 85px;
`;

export const ClientRow = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  border-radius: 5px;
  justify-content: start;
  align-items: center;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const TextBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const DatePicker = styled(DP)`
  width: 150px;
  z-index: 9;
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const DatePicketWrapper = styled.div`
  .offer-dp {
    margin: 0;
  }

  .offer-dp > div:first-child {
    border: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
    border-radius: 5px;
    margin: 0;
    height: 44px;
    align-items: center;
  }

  .react-datepicker-popper {
    z-index: 30;
  }
`;

export const CtaButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const GreenButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const KebabIcon = styled(FontIcon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const KebabIconContainer = styled(ColumnContainer)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  align-items: end;
  justify-content: center;
`;
export const ChatButton = styled(Button)`
  min-width: 0;
  background-color: ${p => p.theme.colors.grey4};
  color: ${p => p.theme.colors.black};
  width: auto;
  padding: 9px 12px;
  box-sizing: border-box;
`;

export const FontDownIcon = styled(FontIcon)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  flex-shrink: 0;
`;

export const DropDownRow = styled(RowContainer)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  gap: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
`;

export const divider = styled.div`
  border-top: 1px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  margin-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
