import { __, currency, date, EventTrack, orderService, sellerWorkspaceService, userSelectors, utils } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import { getDefaultColumns, getOrderSourceText } from '../../../constants';
import { BubbleColor } from '../../atoms/TagBubble/TagBubble.component';
import ContactCell from '../ContactCell';
import Table, { IColumn } from '../Table/Table.component';
import * as S from './OrderTable.styled';

export interface IProps {
  catalogHash?: string;
  catalogs?: { [id: number]: IWorkspace };
  className?: string;
  clients?: { [id: number]: Array<IClient> };
  contacts: { [contactId: number]: IContact };
  dateRangeDescription?: string;
  hasMore?: boolean;
  isUnselect?: boolean;
  markAllAsRead?: () => void;
  me: IUser;
  orderClick: (order: IOrder) => void;
  orders: Array<IOrder>;
  ordersSelected?: Array<number>;
  orderToggleReadStatus: (userId: number, orderId: number, status: boolean) => void;
  orderUnreads?: number;
  removeSelection?: () => void;
  selectable?: boolean;
  selectAll?: () => void;
  sellerFacets?: { [key: string]: number };
  setOrdersSelected?: (ids: Array<number>) => void;
  showContactName?: boolean;
  showOrderSource?: boolean;
  sort?: string;
  sortOrder?: 'desc' | 'asc';
  suppliers?: { [id: number]: Array<ISupplier> };
  totalResults: number;
  touchImage: (images: Array<ImageGalleryObject>, selected: number) => void;
  type?: IOrderType | 'unread';
  updateSortMode?: (sortBy?: string, sortOrder?: 'desc' | 'asc') => void;
}

const OrderTable: React.FC<IProps> = ({
  me,
  catalogHash,
  catalogs,
  clients,
  contacts,
  setOrdersSelected,
  showContactName,
  showOrderSource,
  suppliers,
  type,
  orderToggleReadStatus,
  className = 'order-table-container',
  dateRangeDescription,
  markAllAsRead,
  orderUnreads,
  totalResults,
  orders,
  orderClick,
  removeSelection,
  selectable,
  selectAll,
  sort,
  sortOrder,
  ordersSelected = [],
  isUnselect,
  updateSortMode,
}) => {
  function renderTable() {
    if (orders.length === 0) return null;
    return (
      <Table
        productColumns={getDefaultColumns()}
        updateSortMode={(sort, sortOrder) => updateSortMode?.(sort, sortOrder)}
        sort={sort}
        sortOrder={sortOrder}
        className="orders-table"
        values={orders}
        onClickRow={(data: IOrder) => orderClick(data)}
        emptyText={''}
        columns={getColumns()}
        isReadRow={isReadRow}
        removeSelection={removeSelection}
        selectable={selectable}
        selectAll={selectAll}
        selected={
          (isUnselect && !ordersSelected.length) || (isUnselect && ordersSelected.length === orders.length)
            ? 'all'
            : ordersSelected.length
            ? 'any'
            : 'none'
        }
        showStickyHeader={true}
        scrollClassName="orders-dashboard-scroll-container"
      />
    );
  }
  const togglePriceMismatchEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_PRICE_MISMATCH));

  const isReadRow = React.useCallback(
    (data: IOrder) => {
      const amOwner = me.id === data.sellerId || me.id === data.buyerId || me.id === data.delegateId;
      const isRead = !amOwner || (data.readBy ? !!data.readBy.find(r => r === me.id) : data.isRead);
      return isRead;
    },
    [me],
  );

  function getColumns() {
    let thirdTitle = __('Components.OrdersList.TableDashboard.Contact');
    if (type) {
      thirdTitle =
        type === 'sale'
          ? __('Components.OrdersList.TableDashboard.Client')
          : __('Components.OrdersList.TableDashboard.Supplier');
    }

    return [
      {
        title: '',
        id: 'checkbox',
        width: '50px',
        element: (data: IOrder) => {
          const selected = ordersSelected ? ordersSelected.includes(data.id) : false;
          const checked = selected;
          return (
            <>
              {selectable ? (
                <S.Fill selectable={true}>
                  <S.RadioLabel
                    onClick={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    <S.RadioInput
                      id="selected"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        if (!ordersSelected.includes(data.id)) {
                          setOrdersSelected([...ordersSelected, data.id]);
                        } else {
                          const idx = ordersSelected.indexOf(data.id);
                          if (idx !== -1) {
                            const os = [...ordersSelected];
                            os.splice(idx, 1);
                            setOrdersSelected(os);
                          }
                        }
                      }}
                    />
                    <S.RadioCheckmark checked={checked} />
                  </S.RadioLabel>
                </S.Fill>
              ) : (
                <S.FillCol selectable={false} />
              )}
            </>
          );
        },
      },
      {
        title: __('Components.OrdersList.TableDashboard.Status'),
        id: 'status',
        width: '130px',
        element: (data: IOrder) => {
          return (
            <S.StatusTag
              label={__(`Messages.Order.status.${data.status}`)}
              disabled={true}
              color={data.status as BubbleColor}
            />
          );
        },
      },
      {
        title: catalogHash
          ? __('Components.OrdersList.TableDashboard.Colleague')
          : __('Components.OrdersList.TableDashboard.Workspace'),
        id: 'workspace',
        width: '200px',
        element: (data: IOrder) => {
          // use both parameters id and hash because in realtime we don't have hash
          // remove when order.catalog_id and order.catalog_hash is done through backend
          const catalog = Object.values(catalogs || {}).find(
            c => c.hashId === data.catalogHash || c.id === data.catalogId || c.id === data.buyerWorkspaceId,
          );

          const { buyer, seller } = orderService.getSellerBuyer(
            data,
            contacts,
            me,
            clients?.[catalog?.id],
            suppliers?.[catalog?.id],
          );
          const colleague = type === 'sale' ? seller : buyer;
          return (
            <S.ContactCol>
              {catalogHash ? (
                <ContactCell
                  avatar={colleague.avatar}
                  imgText={colleague.name}
                  isRead={isReadRow(data)}
                  primaryText={colleague.name}
                  status={data.status}
                  workingStatus={colleague.workingStatus}
                />
              ) : catalog ? (
                <ContactCell
                  avatar={catalog.companyImage.replace('w_450', '')}
                  avatarColor={utils.getAvatarColor(sellerWorkspaceService.getCatalogName(catalog, contacts, me))}
                  imgText={sellerWorkspaceService.getCatalogName(catalog, contacts, me)}
                  isRead={isReadRow(data)}
                  primaryText={sellerWorkspaceService.getCatalogName(catalog, contacts, me)}
                  secondaryText={colleague.id !== me.id ? colleague.name : ''}
                  status={data.status}
                  type="team"
                />
              ) : null}
            </S.ContactCol>
          );
        },
      },
      {
        title: __('Components.OrdersList.TableDashboard.Reference'),
        id: 'reference',
        element: (data: IOrder) => {
          return (
            <S.ContactCol>
              <S.HashText isRead={isReadRow(data)} status={data.status}>
                {data.externalIdSeller || data.externalIdBuyer || '#' + data.hashId}
              </S.HashText>
              {showOrderSource ? (
                <S.TextLight status={data.status}>{getOrderSourceText(data.origin)}</S.TextLight>
              ) : null}
            </S.ContactCol>
          );
        },
      },
      ...(showContactName
        ? [
            {
              title: thirdTitle,
              id: 'contact',
              element: (data: IOrder) => {
                const catalog = Object.values(catalogs || {}).find(
                  c => c.hashId === data.catalogHash || c.id === data.catalogId || c.id === data.buyerWorkspaceId,
                );
                const { buyer, seller } = orderService.getSellerBuyer(
                  data,
                  contacts,
                  me,
                  clients?.[catalog?.id],
                  suppliers?.[catalog?.id],
                );
                const otherPart = type === 'sale' ? buyer : seller;
                const otherPartHasCompany = !!otherPart.companyName;
                return (
                  <>
                    {showContactName ? (
                      <ContactCell
                        avatar={otherPartHasCompany ? otherPart.companyLogo.replace('w_450', '') : otherPart.avatar}
                        avatarColor={
                          otherPartHasCompany ? utils.getAvatarColor(otherPart.companyName) : otherPart.avatarColor
                        }
                        imgText={otherPartHasCompany ? otherPart.companyName : otherPart.name}
                        isRead={isReadRow(data)}
                        primaryText={otherPart.companyName ? otherPart.companyName : ''}
                        secondaryText={otherPart.name}
                        status={data.status}
                      />
                    ) : null}
                  </>
                );
              },
            },
          ]
        : []),
      {
        title: __('Components.OrdersList.TableDashboard.Delivery'),
        id: 'delivery',
        sort: 'delivery_estimated_at',
        sortOrder: ['desc', 'asc'],
        element: (data: IOrder) => {
          const hasMissingDeliveryAddress = data.issues.find(err =>
            ['address-not-found', 'address-code-not-found'].includes(err.type),
          );
          return (
            <S.FlexRow>
              {hasMissingDeliveryAddress ? (
                <S.DeliveryCol>
                  <S.TextIssue status={data.status} isRead={isReadRow(data)}>
                    {__('MagicOrders.Table.field_incomplete')}
                  </S.TextIssue>
                </S.DeliveryCol>
              ) : (
                <S.DeliveryCol>
                  {data.deliverTo || data.deliveryEstimatedAt ? (
                    <S.FlexColumnDel>
                      {data.deliveryEstimatedAt ? (
                        <S.TextSecondary isRead={isReadRow(data)} status={data.status}>
                          {utils.firstToUpperCase(
                            date.formatLongDate(data.deliveryEstimatedAt, undefined, undefined, 'EEE, dd MMM yyyy'),
                          )}
                        </S.TextSecondary>
                      ) : null}
                      {data.deliverTo ? (
                        <S.TextBlack isRead={isReadRow(data)} status={data.status}>
                          {data.deliverTo.displayName || data.deliverTo.rest}
                        </S.TextBlack>
                      ) : null}
                    </S.FlexColumnDel>
                  ) : (
                    <S.TextGrey isRead={isReadRow(data)} status={data.status}>
                      -
                    </S.TextGrey>
                  )}
                </S.DeliveryCol>
              )}
            </S.FlexRow>
          );
        },
      },
      ...(config.TOGGLE_LOAD_DATE?.enabled
        ? [
            {
              title: __('Components.ProductsList.Table.LoadDate'),
              id: 'load-date',
              sort: 'load_estimated_at',
              sortOrder: ['desc', 'asc'],
              element: (data: IOrder) => {
                return (
                  <S.FlexRow>
                    <S.DeliveryCol>
                      {data.loadEstimatedAt ? (
                        <S.FlexColumnDel>
                          {data.loadEstimatedAt ? (
                            <S.TextSecondary isRead={isReadRow(data)} status={data.status}>
                              {utils.firstToUpperCase(
                                date.formatLongDate(data.loadEstimatedAt, undefined, undefined, 'EEE, dd MMM yyyy'),
                              )}
                            </S.TextSecondary>
                          ) : null}
                        </S.FlexColumnDel>
                      ) : (
                        <S.TextGrey isRead={isReadRow(data)} status={data.status}>
                          -
                        </S.TextGrey>
                      )}
                    </S.DeliveryCol>
                  </S.FlexRow>
                );
              },
            },
          ]
        : []),
      {
        title: __('Components.OrdersList.TableDashboard.Created'),
        id: 'created-at',
        sort: 'created_at',
        sortOrder: ['desc', 'asc'],
        element: (data: IOrder) => {
          const hourFormat = me.settings.hourFormat;
          const dateFormatted = date.formatLongDate(data.createdAt!, undefined, undefined, 'dd MMM yyyy');
          const dateFormattedHours = date.formatTime(data.createdAt!, hourFormat);
          return (
            <S.CreatedCol>
              <S.FlexColumn>
                <S.TextBlack isRead={isReadRow(data)} status={data.status}>
                  {dateFormatted}
                </S.TextBlack>
                {data.showHours ? (
                  <S.TextSecondary isRead={isReadRow(data)} status={data.status}>
                    {dateFormattedHours}
                  </S.TextSecondary>
                ) : null}
              </S.FlexColumn>
            </S.CreatedCol>
          );
        },
      },
      {
        title: __('Components.OrdersList.TableDashboard.Articles'),
        id: 'articles',
        align: 'center',
        width: '40px',
        element: (data: IOrder) => {
          const missingProductsCount = data.issues.filter(err =>
            ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(err.type),
          ).length;
          return (
            <>
              {missingProductsCount > 0 ? (
                <S.ArticlesCol align="center">
                  <S.TextIssue status={data.status} textAlign="center" isRead={isReadRow(data)}>
                    {__('MagicOrders.Table.field_incomplete')}
                  </S.TextIssue>
                </S.ArticlesCol>
              ) : (
                <S.ArticlesCol align="center">
                  <S.AmountFlexRow>
                    {togglePriceMismatchEnable &&
                    type === 'sale' &&
                    data.issues.find(i => i.type === 'kind-loss-of-line') ? (
                      <S.TooltipPrice
                        position="bottom"
                        text={__('Components.OrdersList.lost_line_info')}
                        width="200px"
                        themeMode="dark"
                      >
                        <S.InfoIcon disableHover={true} name="Info" />
                      </S.TooltipPrice>
                    ) : null}
                    <S.TextArticles isRead={isReadRow(data)} status={data.status}>
                      {data.items.length}
                    </S.TextArticles>
                  </S.AmountFlexRow>
                </S.ArticlesCol>
              )}
            </>
          );
        },
      },
      {
        title: __('Components.OrdersList.TableDashboard.Price'),
        id: 'price',
        align: 'center',
        element: (data: IOrder) => {
          const priceDisplay = priceDisplay0(data);
          const mismatchPriceIssue = data.issues?.find(i => i.type === 'no-pricegroup-matching');
          return (
            <S.PriceCol isRead={isReadRow(data)} status={data.status}>
              <S.PriceFlexRow>
                {togglePriceMismatchEnable && type === 'sale' && mismatchPriceIssue ? (
                  <S.TooltipContainer>
                    <S.TooltipPrice
                      position="bottom"
                      text={__('Components.OrdersList.mismatch_price_tooltip')}
                      width="200px"
                      themeMode="dark"
                    >
                      <S.InfoIcon disableHover={true} name="Info" />
                    </S.TooltipPrice>
                  </S.TooltipContainer>
                ) : null}
                <S.PriceText status={data.status}>{priceDisplay}</S.PriceText>
              </S.PriceFlexRow>
            </S.PriceCol>
          );
        },
      },
      {
        title: '',
        id: 'read',
        width: '50px',
        element: (data: IOrder) => {
          const amOwner = me.id === data.sellerId || me.id === data.buyerId || me.id === data.delegateId;
          return (
            <S.ReadCol align="flex-end">
              {amOwner ? (
                <S.ReadWrapper className="read" isRead={isReadRow(data)} onClick={e => handleToggleReadStatus(data, e)}>
                  <S.ReadIcon name={isReadRow(data) ? 'No-read' : 'Read'} />
                  <S.ReadText className="readText">
                    {isReadRow(data)
                      ? __('Components.OrdersList.mark_as_unread')
                      : __('Components.OrdersList.mark_as_read')}
                  </S.ReadText>
                </S.ReadWrapper>
              ) : null}
            </S.ReadCol>
          );
        },
      },
    ] as Array<IColumn>;
  }

  function priceDisplay0(order: IOrder) {
    if (
      (order.priceMode === 'none' &&
        order.sellerId !== me.id &&
        !sellerWorkspaceService.getMember(
          Object.values(catalogs || {}).find(c => c.hashId === catalogHash),
          me.id,
        )) ||
      order.price === 0
    )
      return '-';
    return currency.getPrice(order.currency, order.price, order.pricePrecision);
  }

  const handleToggleReadStatus = (order: IOrder, e: React.MouseEvent) => {
    // Prevent event propagation to parent elements
    e.stopPropagation();

    // Determine the new read status
    const isCurrentlyRead = order.readBy ? order.readBy.includes(me.id) : order.isRead ?? false;
    const markAsRead = !isCurrentlyRead;

    // Track the event
    EventTrack.track('orders_mark_as_read', {
      order_id: order.id!,
      read: markAsRead,
      type,
    });

    // Call the function to update the order's read status
    orderToggleReadStatus(me.id, order.id, markAsRead);
  };

  return (
    <>
      <S.TotalResultsContainer>
        <S.TextSmall>
          {__('Components.OrdersList.results', { count: totalResults })}
          {dateRangeDescription && !orderUnreads ? ' · ' + dateRangeDescription : null}
          {markAllAsRead && orderUnreads ? (
            <S.LinkBlue onClick={markAllAsRead}>{__('Components.OrdersList.mark_all_as_read')}</S.LinkBlue>
          ) : null}
        </S.TextSmall>
      </S.TotalResultsContainer>
      <S.Container className={className}>{renderTable()}</S.Container>
    </>
  );
};

export default React.memo(OrderTable);
