import {
  __,
  chatSelectors,
  IOfferedProduct,
  IReferenceResponse,
  IReferenceSellerView,
  modalActions,
  prodTypeSelectors,
  userSelectors,
  utils,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import { FontIcon, Select } from '../../atoms';
import ActionsModal from '../ActionsModal';
import AddressModal from '../AddressModal';
import EmptyListResource from '../EmptyListResource';
import Table from '../Table';
import { getColumns as getColumnsV1 } from './ConstantsAndColumns';
import { Availability, DepartureAndDeliveredPrice, getColumns } from './ConstantsAndColumnsDeparturePrice';
import { checkRepeatedClientAssociations, columnsFunction } from './Functions';
import * as S from './OfferedProductTable.styled';

export interface IProps {
  addAddress: (addresses: Array<IAddress>) => void;
  addresses?: Array<IAddress>;
  clientAssociations?: Array<IReferenceSellerView>;
  client: IClient;
  deleteProduct: (id: string) => void;
  editable?: boolean;
  offeredProducts: Array<IOfferedProduct>;
  products: Array<GenericProduct & { cellClassName?: string }>;
  removeAddress: (id: number) => void;
  searchProducts: () => void;
  setHasChanges: () => void;
  setHasReferenceConflicts: (isPublishable: boolean) => void;
  setHasMissingAssociations: (isSaveable: boolean) => void;
  setProductOffer: (sku: string, name: string, value: number | string | boolean, warehouseId?: number) => void;
  references?: Array<IReferenceResponse>;
  loadingReferences?: boolean;
  totalAddresses?: Array<IAddress>;
  workspaceId: string;
  removeAssociation: (referenceId: number, sku: string) => void;
  associateProduct: (referenceId: number, sku: string) => void;
  availability?: Availability;
  departureAndDeliveredPrice?: DepartureAndDeliveredPrice;
  ava?: Availability;
  depa?: DepartureAndDeliveredPrice;
}

const OfferedProductTable: React.FC<IProps> = ({
  addAddress,
  addresses,
  clientAssociations,
  client,
  deleteProduct,
  editable,
  offeredProducts,
  products,
  removeAddress,
  references,
  loadingReferences,
  searchProducts,
  setHasChanges,
  setHasReferenceConflicts,
  setHasMissingAssociations,
  setProductOffer,
  totalAddresses,
  workspaceId,
  removeAssociation,
  associateProduct,
  availability,
  departureAndDeliveredPrice,
}) => {
  const [showSearchAddress, setShowSearchAddress] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<GenericProduct | null>(null);
  const [associatedReferenceId, setAssociatedReferenceId] = React.useState<number | null>(null);
  React.useEffect(() => {
    const newConflicts = products.map(p => {
      const { hasRepeatedSkus } = checkRepeatedClientAssociations(p, clientAssociations, products);
      return { sku: p.sku, error: hasRepeatedSkus };
    });

    if (newConflicts.some(c => c.error)) {
      setHasReferenceConflicts(true);
    } else {
      setHasReferenceConflicts(false);
    }
  }, [products, clientAssociations]);

  const [selectAvailable, setSelectAvailable] = React.useState<string>('');

  // Check if products have missing client associations
  React.useEffect(() => {
    if (
      !products.some(p => {
        const hasReference = clientAssociations.some(c => c.sku === p.sku);
        if (!hasReference) {
          setHasMissingAssociations(true);
          return true;
        }
        return false;
      })
    ) {
      setHasMissingAssociations(false);
    }
  }, [clientAssociations, products]);

  const channels: Record<number, IChannel> = useSelector(chatSelectors.getChannels());
  const history = useHistory();
  const prodTypes = useSelector(prodTypeSelectors.getProdTypes);

  const dispatch = useDispatch<any>();

  const toggleAssociateProductsEnabled = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_SELLER_OFFER_TABLE));
  const toggleDeparturePriceAndTotalQuantities = useSelector(
    userSelectors.hasToggleEnabled(config.TOGGLE_DEPARTURE_PRICE_AND_TOTAL_QUANTITIES),
  );
  const toggleOfferingV1dot1 = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_OFFERING_V_1_1));

  const addressColumns = addresses?.map(address => {
    return { value: address?.city, label: address?.city };
  });

  const getColumns2 = toggleDeparturePriceAndTotalQuantities && toggleOfferingV1dot1 ? getColumns : getColumnsV1;

  const columns = columnsFunction(__);
  if (toggleDeparturePriceAndTotalQuantities) {
    if (
      !(
        availability === Availability.address &&
        departureAndDeliveredPrice === DepartureAndDeliveredPrice.deliveredPrice
      )
    ) {
      columns.push({ value: 'offeredDeparturePrice', label: __('Components.OffersList.table.departurePrice') });
    }
  }
  if (toggleAssociateProductsEnabled) {
    columns.push({ value: 'reference', label: __('Components.OffersList.reference') });
  }

  columns.push(...addressColumns);
  let change2Columns = true;

  if (availability === 'total') {
    change2Columns = false;
  }

  const headerColumnsAddress = addresses?.map((address, i) => {
    return {
      title: address?.city,
      id: 'warehouse' + i,
      width: 'auto',
      colSpan: change2Columns ? 2 : 1,
      isGrouped: true,
      isBlue: address.id !== -1,
      hasCustomCell: true,
      cellClassName: 'offer-bordered-header',
      renderCell: () => {
        return address.id !== -1 ? (
          <S.AddressWrapper>
            <S.AddressCell>
              <S.TextHeaderBold>{address.displayName}</S.TextHeaderBold>
              <S.TextHeader>{`${address.city}, ${address.zip}`}</S.TextHeader>
            </S.AddressCell>
            {editable ? (
              <S.FontIconWrapper>
                <FontIcon
                  name="Close"
                  onClick={() => {
                    removeAddress(address.id);
                    setHasChanges();
                  }}
                />
              </S.FontIconWrapper>
            ) : null}
          </S.AddressWrapper>
        ) : (
          <S.AddressWrapper>
            <S.AddressIcon>
              <FontIcon name="Address" />
            </S.AddressIcon>
            <S.TextZeroCase>
              {
                utils.formatText(
                  editable
                    ? totalAddresses.length > 0
                      ? __('Components.OffersList.table.no_address_selected')
                      : __('Components.OffersList.table.no_address_added')
                    : __('Components.OffersList.table.no_address'),
                  s => <S.TextZeroCaseBold key={s}>{s}</S.TextZeroCaseBold>,
                ) as any // TYPEERROR
              }
            </S.TextZeroCase>
          </S.AddressWrapper>
        );
      },
    };
  });

  const headerColumns = [
    editable
      ? {
          title: '',
          id: '',
          width: '20px',
          colSpan: 3,
          renderCell: () => {
            return (
              <S.CtaButton type="secondary" onClick={searchProducts}>
                <S.AddIcon name="Add-more" />
                {__('Components.OffersList.table.add_products')}
              </S.CtaButton>
            );
          },
        }
      : {
          title: '',
          id: '',
          colSpan: 2,
        },
    {
      title: '',
      id: '',
    },
    {
      title: '',
      id: '',
    },
    ...(toggleAssociateProductsEnabled
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
    ...(availability === Availability.total &&
    departureAndDeliveredPrice !== DepartureAndDeliveredPrice.deliveredPrice &&
    toggleDeparturePriceAndTotalQuantities
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
    ...(availability === Availability.address &&
    departureAndDeliveredPrice !== DepartureAndDeliveredPrice.deliveredPrice &&
    toggleDeparturePriceAndTotalQuantities
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
  ] as Array<any>;

  if (!(availability === 'total' && departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice)) {
    headerColumns.push(...(headerColumnsAddress as any));
  }

  const secondHeaderColumns = [
    {
      title: '',
      id: '',
      width: '40px',
      colSpan: 2,
      renderCell: () => {
        return <S.TitleText>{__('Components.OffersList.table.articles', { count: products.length })}</S.TitleText>;
      },
    },
    {
      title: '',
      id: '',
    },
    ...(toggleAssociateProductsEnabled
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
    ...(availability === Availability.total &&
    departureAndDeliveredPrice !== DepartureAndDeliveredPrice.deliveredPrice &&
    toggleDeparturePriceAndTotalQuantities
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
    ...(availability === Availability.address &&
    departureAndDeliveredPrice !== DepartureAndDeliveredPrice.deliveredPrice &&
    toggleDeparturePriceAndTotalQuantities
      ? [
          {
            title: '',
            id: '',
          },
        ]
      : []),
    ...(!(availability === 'total' && departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice)
      ? [
          {
            title: '',
            id: '',
          },
          {
            title: '',
            id: '',
          },
        ]
      : []),

    ...(editable &&
    totalAddresses.length &&
    !(availability === 'total' && departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice)
      ? [
          {
            title: '',
            id: '',
            width: '20px',
            colSpan: 3,
            renderCell: () => {
              return (
                <>
                  <S.BlueCta onClick={() => setShowSearchAddress(!showSearchAddress)}>
                    <S.AddIcon name="Add-more" /> {__('Components.OffersList.table.add_address')}
                  </S.BlueCta>
                </>
              );
            },
          },
        ]
      : []),
  ] as Array<any>;

  return (
    <S.Container>
      <S.TableContainer>
        <Table
          selectable={false}
          columns={getColumns2({
            addresses,
            availability,
            departureAndDeliveredPrice,
            editable,
            offeredProducts,
            clientAssociations,
            products,
            setProductOffer,
            setHasChanges,
            setSelectAvailable,
            selectAvailable,
            removeReference,
            deleteProduct,
            prodTypes,
            setSelectedProduct,
            toggleAssociateProductsEnabled,
          })}
          headerColumns={headerColumns}
          secondHeaderColumns={secondHeaderColumns}
          emptyText=""
          onClickRow={() => null}
          values={products}
          productColumns={columns}
          showStickyHeader={true}
          rowCursor="default"
        />
      </S.TableContainer>
      {showSearchAddress ? renderModalSearchAddress() : null}
      {selectedProduct ? renderAssociationModal() : null}
    </S.Container>
  );

  function renderModalSearchAddress() {
    return (
      <AddressModal
        isBuyerAddress={true}
        addresses={totalAddresses?.filter(a => !addresses?.find(ad => ad.id === a.id)) || []}
        catalogId={+workspaceId}
        onClose={() => setShowSearchAddress(false)}
        title={__('Components.OrderDetails.transport.address_title')}
        subtitle={__('Components.OffersList.table.address_subtitle')}
        onSelectAddress={(a: Array<IAddress>) => {
          addAddress(a);
          setShowSearchAddress(false);
          setHasChanges();
        }}
        withMultipleSelection={true}
      />
    );
  }
  function renderAssociationModal() {
    const referencesOptions = references.map((r: any) => ({
      value: r.id,
      label: r.name,
    }));
    const channelToShow = Object.values(channels).find(
      chan => chan.type === 'private' && chan.members.find(cm => cm.id === +client?.userId),
    );
    const selectedOption = referencesOptions.find(a => a.value === associatedReferenceId);
    return (
      <ActionsModal
        minHeight="300px"
        width="500px"
        onClose={() => {
          setSelectedProduct(null);
          setAssociatedReferenceId(null);
        }}
        title={
          referencesOptions.length > 0 ? __('Components.OffersList.table.modal.title', { supplier: client.name }) : ''
        }
        subtitle={referencesOptions.length > 0 ? __('Components.OffersList.table.modal.text') : ''}
        overFlowVisible={true}
      >
        {referencesOptions.length > 0 ? (
          <>
            <S.InputTitle>{__('Components.OffersList.table.modal.input_title')}</S.InputTitle>
            <Select
              containerMargin={'0'}
              value={selectedOption?.value}
              name="reference-name"
              options={referencesOptions}
              onChange={(name, id) => {
                setAssociatedReferenceId(+id);
              }}
              width="100%"
            />
            <S.ModalRow>
              <S.ModalButton
                type="secondary"
                onClick={() => {
                  setSelectedProduct(null);
                  setAssociatedReferenceId(null);
                }}
              >
                {__('Components.OrderDetails.issues_modal.cancel')}
              </S.ModalButton>
              <S.ModalButton
                disabled={false}
                onClick={async () => {
                  await associateProduct(associatedReferenceId, selectedProduct.sku);
                  setSelectedProduct(null);
                  setAssociatedReferenceId(null);
                  setHasChanges();
                }}
              >
                {__('Components.OrderDetails.issues_modal.cta')}
              </S.ModalButton>
            </S.ModalRow>
          </>
        ) : (
          <EmptyListResource
            imageUrl={IMAGES.emptyBox}
            showButton={true}
            text={__('Components.OffersList.table.modal.zero_case_title')}
            text2={__('Components.OffersList.table.modal.zero_case_text')}
            loading={loadingReferences}
            loadingText={__('Components.OffersList.table.modal.loading_case_title')}
            loadingText2={__('Components.OffersList.table.modal.loading_case_text')}
            buttonText={__('Components.OffersList.table.modal.cta_chat')}
            buttonAction={() => {
              if (channelToShow) {
                dispatch(
                  navActions.navigateChannelByPath(ROUTE_PATHS.CONTACT, channelToShow.id, (path: string) =>
                    history.push(path),
                  ),
                );
              }
            }}
            imageSize="160px"
          />
        )}
      </ActionsModal>
    );
  }
  function removeReference(sku: string, associatedReferenceId: number) {
    dispatch(
      modalActions.modalOpen(
        __('Components.OffersList.table.remove_ref_text'),
        async () => {
          await removeAssociation(associatedReferenceId, sku);
          setSelectedProduct(null);
          setAssociatedReferenceId(null);
          dispatch(modalActions.modalClose());
        },
        {
          showCancelButton: true,
          buttonText: __('Components.Referential.reference.delete'),
          text2: __('Components.OffersList.table.remove_ref_title'),
          actionType: 'dangerous',
          icon: IMAGES.error,
        },
        'nice',
      ),
    );
  }
};

export default OfferedProductTable;
