import { AxiosError } from "axios";
import {
  __,
  colors,
  EXTERNAL_STATUS,
  modalActions,
  notificationsActions,
  ORDER_ORIGIN,
  ORDER_STATUS,
  orderActions,
  orderService,
} from "common-services";
import OrderButton from "./OrderButton.component";
import * as React from "react";
import { useDispatch } from "react-redux";

import { IMAGES } from "../../../../../assets";
import { logError } from "../../../../../services/log";
import { SimpleDropdown, Tooltip } from "../../../../atoms";
import * as S from "./ActionsCard.styled";

export interface IProps {
  acceptOrder: typeof orderActions.acceptOrder;
  anyPriceEmpty: boolean;
  askForAutoAccept: () => void;
  buyerName: string;
  cancelOrder: typeof orderActions.cancelOrder;
  cart: ICart;
  cartClean: (notUpdate?: boolean) => void;
  cloneOrder: () => void;
  closeCart: () => void;
  contactId: number;
  contactName: string;
  editAfterAccept?: boolean;
  hasIssues: boolean;
  isBlocked: boolean;
  isFavoriteCheck: boolean;
  isPro: boolean;
  me: IUser;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navCloneAction: () => void;
  navigateToOrders?: () => void;
  notificationShow: typeof notificationsActions.notificationShow;
  openedFrom?: "other" | "purchases" | "sales";
  order?: IOrder;
  orderNew: typeof orderActions.orderNew;
  orderSendToERP: typeof orderActions.orderSendToERP;
  orderUpdate: (order: IOrder) => void;
  previousComments: Array<IMessage>;
  resetOrder: () => void;
  self?: boolean;
  sellerName: string;
  sendOrderManuallyToERP: boolean;
  setShowTooltip: (show: boolean) => void;
  weAreSeller: boolean;
}

const ActionsCard: React.FC<IProps> = ({
  acceptOrder,
  anyPriceEmpty,
  askForAutoAccept,
  buyerName,
  cancelOrder,
  cart,
  cartClean,
  cloneOrder,
  closeCart,
  contactId,
  contactName,
  editAfterAccept,
  hasIssues,
  isBlocked,
  isFavoriteCheck,
  isPro,
  me,
  modalClose,
  modalOpen,
  navCloneAction,
  navigateToOrders,
  notificationShow,
  openedFrom,
  order,
  orderNew,
  orderSendToERP,
  orderUpdate,
  previousComments,
  resetOrder,
  self,
  sellerName,
  sendOrderManuallyToERP,
  weAreSeller,
  setShowTooltip,
}) => {
  const dispatch = useDispatch<any>();
  if (!order || isBlocked) return null;
  const myId = me.id!;
  const [hasBeenSentToERP, setHasBeenSentToERP] = React.useState(false);
  const orderLiterals = orderService.getOrderActionLiterals(
    order,
    weAreSeller,
    self,
    buyerName,
    sellerName
  );
  const { ctaScreen } = orderLiterals;

  const showSendToERPCta =
    sendOrderManuallyToERP &&
    weAreSeller &&
    [
      ORDER_STATUS.ACCEPTED,
      ORDER_STATUS.PENDING,
      ORDER_STATUS.INCOMPLETE,
    ].includes(order.status) &&
    [
      ORDER_ORIGIN.INTERNAL,
      ORDER_ORIGIN.IMPORT_UI,
      ORDER_ORIGIN.EMAIL,
    ].includes(order.origin) &&
    !order.sentToERP &&
    !hasBeenSentToERP;
  if (hasIssues && order.status !== ORDER_STATUS.CANCELED && !showSendToERPCta)
    return (
      <S.CancelLink
        key="cancel_button"
        id="cta-cancel-button"
        onClick={handleCancelOrder}
      >
        {__("Components.OrderDetails.options.cancel")}
      </S.CancelLink>
    );
  const buttonCTA = hasIssues ? null : getCTA();

  const dropDownOptions = [
    ...((buttonCTA || showSendToERPCta) &&
    order.status !== "pending" &&
    !hasIssues
      ? [
          {
            key: "clone",
            value:
              order.status === ORDER_STATUS.DRAFT
                ? __("Components.OrderDetails.options.duplicate_draft")
                : __("Components.OrderDetails.options.clone"),
          },
        ]
      : []),
    ...(order.externalStatusBuyer === EXTERNAL_STATUS.Unknown &&
    order.externalStatusSeller === EXTERNAL_STATUS.Unknown &&
    order.status !== ORDER_STATUS.DRAFT
      ? [
          {
            key: "cancel",
            value: __("Components.OrderDetails.options.cancel"),
            color: colors.red1,
          },
        ]
      : []),
    ...(order.id && order.status === ORDER_STATUS.DRAFT
      ? [
          {
            key: "delete",
            value: __("Components.OrderDetails.options.delete_draft"),
            color: colors.red1,
          },
        ]
      : []),
  ];
  const orderHasChanges =
    order.hasChanges ||
    order.changedLogistics ||
    order.changedItems ||
    order.changedCustomItems;
  return (
    <>
      {(order.status === "draft" && orderHasChanges) ||
      (order.status === "pending" &&
        weAreSeller &&
        buttonCTA &&
        !order.id &&
        isPro)
        ? getSaveDraftCTA()
        : null}
      {buttonCTA ||
        (showSendToERPCta ? (
          <Tooltip
            text={
              hasIssues
                ? __("Components.OrderDetails.send_erp_tooltip.issues")
                : __("Components.OrderDetails.send_erp_tooltip.pending")
            }
            disabled={!hasIssues && order.status === ORDER_STATUS.ACCEPTED}
            themeMode="dark"
            position="bottom"
          >
            <S.ActionButton
              type="principal"
              id="cta-send-order-to-erp-button"
              onClick={sendOrderToErp}
              disabled={hasIssues || order.status !== ORDER_STATUS.ACCEPTED}
            >
              {__("Components.OrderDetails.options.send_to_erp")}
            </S.ActionButton>
          </Tooltip>
        ) : null) ||
        (order.id && navCloneAction && !hasIssues ? (
          <S.OptionButton
            key="clone_button"
            id="cta-clone-button"
            onClick={cloneOrder}
            type="secondary"
          >
            {__("Components.OrderDetails.options.clone")}
          </S.OptionButton>
        ) : null)}
      {editAfterAccept ? (
        <S.OptionButton
          key="reset_button"
          id="cta-reset-button"
          onClick={resetOrder}
          type="secondary"
        >
          {__("Components.OrderDetails.options.reset")}
        </S.OptionButton>
      ) : !buttonCTA && order.status === "canceled" ? null : (
        <SimpleDropdown
          hAlign="right"
          onSelect={handleExtendedOptions}
          options={dropDownOptions}
        >
          <S.Dots id="products-Kebab" name="Kebab" />
        </SimpleDropdown>
      )}
    </>
  );

  /**
   * Get main action (CTA) button
   */
  function getCTA() {
    if (
      (order.changedLogistics ||
        (order.hasChanges && (order.status !== "pending" || self)) ||
        order.changedItems ||
        order.changedCustomItems ||
        !order.id ||
        order.status === ORDER_STATUS.DRAFT) &&
      (!anyPriceEmpty || !weAreSeller)
    ) {
      return (
        <OrderButton
          order={order}
          me={me}
          cart={cart}
          contactId={contactId}
          previousComments={previousComments}
          closeCart={closeCart}
          ctaScreen={ctaScreen}
          orderNew={orderNew}
          orderUpdate={orderUpdate}
          showSuccessModal={showSuccessModal}
          setShowTooltip={setShowTooltip}
        />
      );
    }

    if (self) {
      return null;
    }
    if (
      order.status === "pending" &&
      !orderService.amLastAuthor(order, {
        weAreSeller,
        weAreBuyer: !weAreSeller,
      })
    ) {
      return (
        <S.ActionButton
          id="cta-accept-button"
          onClick={() =>
            acceptOrder(order, myId, isFavoriteCheck, () => {
              askForAutoAccept();
              if (contactId && openedFrom === "other") closeCart();
            })
          }
          disabled={anyPriceEmpty}
          type="principal"
        >
          {ctaScreen}
        </S.ActionButton>
      );
    } else {
      return null;
    }
  }

  /**
   * Return save draft CTA
   */
  function getSaveDraftCTA() {
    return (
      <S.ActionButton
        id="cta-draft-button"
        onClick={() => {
          setTimeout(() => {
            if (order.id) {
              return dispatch(
                orderActions.orderDraftSave(
                  order,
                  me.id!,
                  (o?: IOrder, err?: AxiosError) => {
                    notificationShow({
                      title: err
                        ? __("Components.Onboarding.error_generic")
                        : __(
                            "Components.OrderDetails.draft_notification.title"
                          ),
                      subtitle: err
                        ? ""
                        : __(
                            "Components.OrderDetails.draft_notification.description"
                          ),
                      style: err ? "error" : "success",
                      closable: true,
                    });
                  }
                )
              );
            } else {
              order.initialComments = previousComments;
              return dispatch(
                orderActions.orderDraftSave(
                  {
                    ...order,
                    externalIdBuyer: cart.externalIdBuyer,
                    externalIdSeller: cart.externalIdSeller,
                  },
                  me.id!,
                  (o?: IOrder, err?: AxiosError) => {
                    notificationShow({
                      title: err
                        ? __("Components.Onboarding.error_generic")
                        : __(
                            "Components.OrderDetails.draft_notification.title"
                          ),
                      subtitle: err
                        ? ""
                        : __(
                            "Components.OrderDetails.draft_notification.description"
                          ),
                      style: err ? "error" : "success",
                      closable: true,
                    });
                    if (contactId) {
                      cartClean(false);
                      closeCart();
                      navigateToOrders?.();
                    }
                  }
                )
              );
            }
          });
        }}
        disabled={anyPriceEmpty}
        type="secondary"
      >
        {__("Components.OrderDetails.options.save_draft")}
      </S.ActionButton>
    );
  }

  /**
   * Show a success modal when creating an order
   */
  function showSuccessModal(newOrder: IOrder) {
    notificationShow({
      title: __("Components.OrderDetails.success_create.title", {
        hashId:
          newOrder.externalIdSeller ||
          newOrder.externalIdBuyer ||
          "#" + newOrder.hashId,
      }),
      closable: true,
      subtitle: __("Components.OrderDetails.success_create.description", {
        name: contactName,
      }),
      style: "success",
    });
  }

  /**
   * Cancel order if users confirms ui-dialog
   *
   * If order is still not created, instead of real-cancel we clean cart and navigate
   */
  function handleCancelOrder() {
    modalOpen(
      __("Components.OrderDetails.confirm_cancel"),
      () => {
        if (order.id) {
          cancelOrder(order, myId, () => contactId && closeCart());
        } else {
          cartClean(false);
          modalClose();
          closeCart();
        }
      },
      {
        buttonText: __("Components.Cart.cancel_order"),
        buttonCancelText: __("Components.Cart.skip"),
        icon: IMAGES.cautionGrape,
        showCancelButton: true,
      },
      "nice"
    );
  }

  /**
   * handle on click in clone/remove option
   */
  function handleExtendedOptions(op: string) {
    switch (op) {
      case "clone":
        cloneOrder();
        break;
      case "cancel":
        handleCancelOrder();
        break;
      case "delete":
        handleDeleteDraft();
        break;
      default:
        logError(
          new Error("unknow option: " + op),
          "orderDetails.extended.options"
        );
        break;
    }
  }

  /**
   *  Send order manually to ERP
   */
  function sendOrderToErp() {
    modalOpen(
      __("Components.OrderDetails.send_erp_confirmation.title"),
      () => {
        orderSendToERP(me.id, order.id!, error => {
          notificationShow({
            title: error
              ? __("Components.OrderDetails.send_erp_notification.error")
              : __("Components.OrderDetails.send_erp_notification.success"),
            subtitle: "",
            style: error ? "error" : "success",
            closable: true,
          });
          modalClose();
          if (!error) setHasBeenSentToERP(true);
        });
      },
      {
        text2: __("Components.OrderDetails.send_erp_confirmation.description"),
        showCancelButton: true,
        buttonText: __("Components.OrderDetails.send_erp_confirmation.confirm"),
        buttonCancelText: __(
          "Components.OrderDetails.send_erp_confirmation.cancel"
        ),
        icon: IMAGES.informativePineapple,
      },
      "nice"
    );
  }

  /**
   * Delete a draft
   */
  function handleDeleteDraft() {
    modalOpen(
      __("Components.OrderDetails.confirm_delete_draft"),
      () => {
        if (order.id) {
          modalClose();
          if (contactId) closeCart();
          dispatch(
            orderActions.orderDraftDelete(order, me.id!, () => {
              cartClean(false);
              notificationShow({
                title: __(
                  "Components.OrderDetails.draft_delete_notification.title"
                ),
                subtitle: "",
                style: "success",
                closable: true,
              });
            })
          );
        } else {
          cartClean(false);
          modalClose();
          closeCart();
        }
      },
      {
        buttonText: __("Components.Cart.cancel_order"),
        buttonCancelText: __("Components.Cart.skip"),
        icon: IMAGES.cautionGrape,
        showCancelButton: true,
      },
      "nice"
    );
  }
};

export default ActionsCard;
