// OrderButton.tsx
import * as React from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { AxiosError } from "axios";
import {
  ICart,
  IMessage,
  IOrder,
  IUser,
  ORDER_STATUS,
  __,
  orderActions,
} from "common-services";

import * as S from "./ActionsCard.styled";

interface OrderButtonProps {
  order: IOrder;
  me: IUser;
  cart: ICart;
  contactId?: number;
  previousComments: Array<IMessage>;
  closeCart: () => void;
  ctaScreen: React.ReactNode;
  orderNew: typeof orderActions.orderNew;
  orderUpdate: (order: IOrder) => void;
  showSuccessModal(newOrder: IOrder): void;
  setShowTooltip: (show: boolean) => void;
}

/**
 * OrderButton Component handles order creation and updates with debouncing and loading states
 * to prevent multiple submissions
 */
const OrderButton: React.FC<OrderButtonProps> = ({
  order,
  me,
  cart,
  contactId,
  closeCart,
  ctaScreen,
  previousComments,
  orderNew,
  orderUpdate,
  showSuccessModal,
  setShowTooltip,
}) => {
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  // Handle API success response
  const handleSuccess = React.useCallback(
    (newOrder?: IOrder) => {
      setIsLoading(false);
      if (contactId) {
        closeCart();
      }
      if (newOrder) {
        showSuccessModal(newOrder);
      }
    },
    [contactId, closeCart]
  );

  // Handle API error response
  const handleError = React.useCallback((err?: AxiosError) => {
    setIsLoading(false);
    setError(err?.message || "An error occurred");
    console.error(error);
    if (err?.response?.status === 409) {
      setShowTooltip(true);
    }
  }, []);

  // Debounced order creation function
  const debouncedOrderNew = React.useCallback(
    debounce(async (orderData: IOrder, userId: number) => {
      try {
        const result = await dispatch(
          orderNew(
            {
              ...orderData,
              externalIdBuyer: cart.externalIdBuyer,
              externalIdSeller: cart.externalIdSeller,
            },
            userId,
            (o?: IOrder, err?: AxiosError) => {
              if (err) {
                handleError(err);
              } else {
                handleSuccess(o);
              }
            }
          )
        );
        return result;
      } catch (err) {
        handleError(err as AxiosError);
      }
    }, 300), // 300ms debounce time
    [dispatch, cart, handleSuccess, handleError]
  );

  // Debounced order update function
  const debouncedOrderUpdate = React.useCallback(
    debounce(async (orderData: IOrder) => {
      try {
        await orderUpdate(orderData);
        handleSuccess();
      } catch (err) {
        handleError(err as AxiosError);
      }
    }, 300),
    [handleSuccess, handleError]
  );

  // Click handler
  const handleClick = React.useCallback(async () => {
    // Prevent multiple clicks while loading
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);

    if (order.id) {
      if (order.status === ORDER_STATUS.DRAFT) {
        dispatch(
          orderActions.orderDraftSend(order, me.id!, () => {
            if (contactId) closeCart();
            setIsLoading(false);
          })
        );
      } else {
        await debouncedOrderUpdate(order);
      }
    } else {
      const orderData = {
        ...order,
        initialComments: previousComments,
      };
      await debouncedOrderNew(orderData, me.id!);
    }
  }, [
    isLoading,
    order,
    me.id,
    contactId,
    closeCart,
    debouncedOrderUpdate,
    debouncedOrderNew,
  ]);

  return (
    <>
      <S.ActionButton
        id="cta-action-button"
        type="principal"
        onClick={handleClick}
        disabled={isLoading} // Disable button while loading
        aria-busy={isLoading}
      >
        {isLoading ? __("Global.Commons.Actions.processing") : ctaScreen}
      </S.ActionButton>
    </>
  );
};

export default React.memo(OrderButton);
