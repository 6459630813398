import styled, { ITheme } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, Input, RowContainer, TextLabel } from '../../atoms';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  hasError?: boolean;
}

export const Container = styled(ColumnContainer)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${(p: IStyledProps) => p.theme.colors.white};
  width: 100%;
  padding: 0;
`;

export const TableContainer = styled(RowContainer)`
  max-height: 100%;
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  table thead {
    background-size: cover;
  }

  table thead tr th {
    border: none;
  }

  .offer-bordered-header {
    outline: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
  .offer-bordered-cell-left {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
    border-left: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
  .offer-bordered-cell-right {
    padding-left: ${(p: IStyledProps) => p.theme.paddingSize(1)};
    padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
    border-right: 2px solid ${(p: IStyledProps) => p.theme.colors.grey3};
  }
`;

export const ProductsContainer = styled(RowContainer)`
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  align-items: baseline;
`;

export const InputRow = styled(RowContainer)``;

const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => (p.disabled ? p.theme.colors.grey2 : p.theme.colors.black)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
export const ZeroCaseReference = styled(TextBlack)`
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;
export const WarningText = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
`;
export const WarningRow = styled(RowContainer)``;
export const ReferenceRow = styled(RowContainer)`
  background-color: ${(p: IStyledProps) => p.theme.colors.grey3};
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const CloseIcon = styled(FontIcon)`
  padding-left: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TextBold = styled(TextBlack)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;

export const IconWrapper = styled.div`
  width: auto;
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;
export const RemoveIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.big};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;
export const CtaButton = styled(Button)`
  margin: 0 ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const CtaRow = styled.span`
  z-index: 9;
  top: 48px;
  width: fit-content;
  display: flex;
  position: relative;
  align-items: end;
  flex-direction: row;
`;
export const AddIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  width: ${(p: IStyledProps) => p.theme.fontSize.big};
  height: ${(p: IStyledProps) => p.theme.fontSize.big};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;
export const TitleText = styled(Text)`
  float: left;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize()};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  display: inline;
  white-space: nowrap;
`;
export const BlueCta = styled(Text)`
  display: flex;
  align-items: center;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  margin-left: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  cursor: pointer;
`;
export const ReferenceCta = styled(BlueCta)`
  margin-left: 0;
`;
export const AddressCell = styled(ColumnContainer)`
  width: 100%;
  height: min-height;
`;
export const AddressWrapper = styled(RowContainer)`
  width: min-width;
  height: min-height;
  align-items: center;
`;
export const FontIconWrapper = styled.label`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
`;
export const AddressIcon = styled.label`
  font-size: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(2)};
  cursor: pointer;
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;
export const TextHeader = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: center;
`;
export const TextHeaderBold = styled(TextHeader)`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
`;
export const TextZeroCase = styled(TextLabel)<IStyledProps>`
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const TextZeroCaseBold = styled(TextLabel)<IStyledProps>`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  align-self: center;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(0.5)};
  display: inline;
`;
export const InputTitle = styled(TextLabel)`
  margin: ${(p: IStyledProps) => p.theme.paddingSize(2)} 0;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const ModalRow = styled(RowContainer)`
  justify-content: space-between;
  margin-top: ${(p: IStyledProps) => p.theme.paddingSize(4)};
  align-items: center;
`;
export const ModalButton = styled(Button)`
  width: 45%;
`;
export const ProductWrapper = styled(ColumnContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;
export const WarningIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.orange1};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const AmountInput = styled(Input)`
  border: ${(p: IStyledProps) => `1px solid ${p.hasError ? p.theme.colors.red1 : p.theme.colors.grey2}`};
  &:focus-within {
    border: ${(p: IStyledProps) => `1px solid ${p.hasError ? p.theme.colors.red1 : p.theme.colors.green1}`};
  }
`;
