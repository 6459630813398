import { __ } from 'common-services';
import * as React from 'react';
import * as S from './OrderMagicImport.styled';

/**
 * Render import order text area
 */
export const TextArea: React.FC<{
  disabled: boolean;
  text: string;
  setParentState: (state: any) => void;
  importOrders: () => void;
}> = ({ disabled, text, importOrders, setParentState }) => {
  return (
    <S.TextAreaContainer>
      <S.TextArea
        name="import-text-area"
        onChange={e => setParentState({ text: e.target.value })}
        placeholder={__('Components.OrdersImport.insert_text')}
        rows={8}
        value={text}
        disabled={disabled}
      />
      <S.TextAreaFooter className="text-area-footer">
        <S.ButtonUpload onClick={importOrders} disabled={!text} type="principal" iconName="Share-web" iconSize="15px">
          {__('Components.OrdersImport.import')}
        </S.ButtonUpload>
      </S.TextAreaFooter>
    </S.TextAreaContainer>
  );
};

export default TextArea;
