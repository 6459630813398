import { __, orderService, userSelectors } from 'common-services';
import * as S from './OrderMagicImport.styled';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../../reducers';
import { useParams } from 'react-router-dom';
import { IFormatSelectedCardProps } from './TypesAndConstants';
import * as React from 'react';

const FormatSelectedCard: React.FC<IFormatSelectedCardProps> = ({
  formats,
  formatSelected,
  setParentState,
  parentState,
}) => {
  const me = useSelector(userSelectors.getUser);
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const catalogs = useSelector((state: IReduxState) => state.catalog.catalogs);
  const catalogIdSelected = catalogs[workspaceId as unknown as number].id;
  const orderFormat = formats.find(of => of.slug === parentState?.formatSelected);
  const myRole = catalogs[catalogIdSelected].members.find(member => member.userId === me.id).role;
  const canIEdit = myRole !== 'viewer' ? true : false;
  if (!orderFormat) return null;
  const { label, slug, logo_url, accepted_doc_types } = orderFormat;
  const isSelected = slug === formatSelected;
  return (
    <S.OrderFormatSelectedContainer>
      <S.OrderFormatSquare
        isSelected={isSelected}
        onClick={() => setParentState({ formatSelected: slug, showAllFormats: false })}
      >
        <S.OrderFormatLogo src={logo_url.replace('w_250', 'w_100')} />
      </S.OrderFormatSquare>
      <S.OrderFormatSelectedRight>
        <S.OrderFormatTitle>{label}</S.OrderFormatTitle>
        <S.OrderFormatSubTitle>
          {orderService.getFormatTypeLabel(accepted_doc_types.join(',') || '')}
        </S.OrderFormatSubTitle>
        {canIEdit ? (
          <S.ButtonLink
            type="link"
            iconName="Edit"
            iconSize="15px"
            onClick={() => setParentState({ showAllFormats: true })}
            withoutPadding={true}
          >
            {__('MagicOrders.change_format')}
          </S.ButtonLink>
        ) : null}
      </S.OrderFormatSelectedRight>
    </S.OrderFormatSelectedContainer>
  );
};

export default FormatSelectedCard;
