import { __, notificationsActions } from 'common-services';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import * as S from './OrderMagicImport.styled';
import { IFileDropzoneProps } from './TypesAndConstants';
import * as React from 'react';

/**
 * Render files drop zone
 */

const FileDropzone: React.FC<IFileDropzoneProps> = ({
  disabled,
  files,
  formats,
  formatSelected,
  importOrders,
  setFiles,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const orderFormatSelected = formats.find(of => of.slug === formatSelected);
  if (!orderFormatSelected) return null;
  return (
    <div>
      <Dropzone
        accept={orderFormatSelected.accepted_doc_types}
        multiple={true}
        onDrop={acceptedFiles => {
          if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
          } else {
            dispatch(
              notificationsActions.notificationShow(
                {
                  title: __('ProductEdit.additionalFiles.format_error.title'),
                  subtitle: __('ProductEdit.additionalFiles.format_error.description', {
                    formats: orderFormatSelected.accepted_doc_types,
                  }),
                  closable: true,
                  style: 'error',
                },
                6000,
              ),
            );
          }
        }}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <S.DropzoneContent {...getRootProps()} isDragActive={isDragActive}>
              <input
                type="file"
                multiple={true}
                accept={orderFormatSelected.accepted_doc_types.join(',')}
                {...getInputProps()}
              />
              <S.UploadFileIcon name="Upload" />
              <S.TextUpload>
                {__('MagicOrders.add_orders')}
                {files.length ? (
                  <>
                    {' · '}
                    <S.TextUploadGreen> {__('MagicOrders.orders_added', { count: files.length })}</S.TextUploadGreen>
                  </>
                ) : null}
              </S.TextUpload>
              <S.TextCenter>{__('MagicOrders.add_orders_description')}</S.TextCenter>
              <S.SelectButton type={files.length ? 'secondary' : 'principal'} disabled={disabled}>
                {files.length ? __('MagicOrders.select_more') : __('Components.OrdersImport.select')}
              </S.SelectButton>
            </S.DropzoneContent>
          );
        }}
      </Dropzone>
      {files.length ? (
        <S.SelectButton type="principal" onClick={importOrders}>
          {__('MagicOrders.cta')}
        </S.SelectButton>
      ) : null}
    </div>
  );
};

export default FileDropzone;
