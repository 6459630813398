import {
  __,
  constants,
  contactSelectors,
  CURRENCY_CODES,
  date,
  debounce,
  EventTrack,
  orderActions,
  orderService,
  qs,
  RenderTrack,
  userSelectors,
} from 'common-services';
import { fetchProductFields } from 'common-services/dist/product/service';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import Layout from '../../../layout-flex';
import OrderDetails from '../../../screens/order-details';
import ShowroomBuy from '../../../screens/showroom-buy';
import ShowroomSell from '../../../screens/showroom-sell';
import { navSelectors } from '../../../selectors';
import { api } from '../../../store';
import { useGetProductName } from '../../../util/hooks';
import getPath from '../../../util/routes';
import { Button } from '../../atoms';
import { AggregationTable, EmptyListResource, OrderAggregationFooter } from '../../molecules';
import * as S from './OrderAggregation.styled';

const OrderAggregation = () => {
  const location = useLocation();
  const history = useHistory();
  const getProductName = useGetProductName();
  const me = useSelector(userSelectors.getUser);
  const workspaceSelected = useSelector(navSelectors.getSelectedWorkspace);
  const contacts = useSelector(contactSelectors.getContacts());

  const dispatch = useDispatch<any>();
  const params = useParams<{ workspaceId?: string; asSeller?: string }>();
  const { workspaceId } = params;

  const asSeller = location.pathname.includes('/sales/');

  const search = location.search;

  const { currency, selected, since, type, only_unreads, until, unselected } = qs.parse(search || '', [
    'currency',
    'range',
    'selected',
    'unselected',
    'since',
    'type',
    'until',
    'only_unreads',
  ]) as {
    currency?: CURRENCY_CODES;
    selected?: string;
    unselected?: string;
    since?: string;
    type?: string;
    until?: string;
    only_unreads?: string;
  };
  const beginDate = since && Number(since) ? new Date(Number(since)) : undefined;
  const endDate = until && Number(until) ? new Date(Number(until)) : undefined;

  const [facets, setFacets] = React.useState<any>({});
  const [searchState, setSearchState] = React.useState({
    language: me.settings.language as LOCALE,
    myId: me.id,
    currency: currency || undefined,
    beginDate,
    endDate,
    buyerWsHash: type === 'purchase' ? workspaceSelected?.hashId : undefined,
    catalogHash: type === 'sale' ? workspaceSelected?.hashId : undefined,
    selectedIds: selected ? selected.split(',').map(id => Number(id)) : [],
    unselectedIds: unselected ? unselected.split(',').map(id => Number(id)) : [],
    onlyAggregation: true,
    onlyUnreads: only_unreads === 'true',
  });

  const [orderSelected, setOrderSelected] = React.useState<IOrder>();
  const [showroomSelected, setShowroomSelected] = React.useState<
    CHANNEL_SECTIONS.SHOWROOM_BUY | CHANNEL_SECTIONS.SHOWROOM_SELL
  >();
  const [generatingPDF, setGeneratingPDF] = React.useState<boolean>();
  const [productFields, setProductFields] = React.useState<Record<string, {}>>();

  const t = React.useRef<number>(Date.now());

  const sendSearch = React.useCallback(
    debounce(() => {
      orderService.searchOrderItem(searchState, config.SEARCH_PREFIX, api, me.id).then(({ data }) => {
        setFacets(data?.facets || {});
      });
    }, 200),
    [searchState, me.id],
  );

  const fetchProductFieldsData = () => {
    if (workspaceSelected && facets?.byHash) {
      const productHashes = Object.keys(facets?.byHash);
      const fields = ['product_title', 'hash_id'];
      fetchProductFields(workspaceSelected?.hashId, productHashes, fields, api, config.SEARCH_PREFIX, me.id)
        .then(result => {
          setProductFields(result);
        })
        .catch(error => console.error(error));
    }
  };

  React.useEffect(() => {
    RenderTrack.track('OrderAggregation', { renderTime: t.current });
    sendSearch();
  }, []);

  React.useEffect(() => {
    setSearchState(prevState => ({
      ...prevState,
      buyerWsHash: type === 'purchase' ? workspaceSelected?.hashId : undefined,
      catalogHash: type === 'sale' ? workspaceSelected?.hashId : undefined,
    }));
  }, [workspaceSelected]);

  React.useEffect(() => {
    sendSearch();
  }, [searchState]);

  React.useEffect(() => {
    fetchProductFieldsData();
  }, [facets]);

  const activeDatesFilterStart = searchState.beginDate && searchState.beginDate.getTime();
  const activeDatesFilterEnd = searchState.endDate && searchState.endDate.getTime();
  const hourFormat = me.settings.hourFormat;

  let filtersAppliedText = '';
  if (activeDatesFilterStart || activeDatesFilterEnd) {
    if (activeDatesFilterStart && activeDatesFilterStart === activeDatesFilterEnd) {
      filtersAppliedText += date.dateToText(activeDatesFilterStart, hourFormat).date;
    } else {
      filtersAppliedText += `${__('Components.OrdersList.from')}${
        (searchState.beginDate && date.dateToText(activeDatesFilterStart, hourFormat).date) || '__'
      } ${__('Components.OrdersList.to')} ${
        (activeDatesFilterEnd && date.dateToText(activeDatesFilterEnd, hourFormat).date) || '__'
      }`;
    }
  }

  const handleDownloadPDF = React.useCallback(async () => {
    setGeneratingPDF(true);
    EventTrack.track('download_orders_grouped_pdf');
    const downloadOrderAggregationPdf = (await import('./OrderAggregation.download')).default;

    await downloadOrderAggregationPdf({
      contacts,
      facets: facets?.byHash,
      me,
      workspace: workspaceSelected,
      beginDate: searchState.beginDate,
      endDate: searchState.endDate,
      selected: searchState.selectedIds.join(','),
      unselected: searchState.unselectedIds.join(','),
      currency: searchState.currency,
      asSeller: !!asSeller,
      getProductName,
      productFields,
    });
    setGeneratingPDF(false);
  }, [contacts, facets, me, workspaceSelected, searchState, asSeller, getProductName, productFields]);

  /**
   * Render the order selected in the new cart screen
   */
  const renderNewCart = () => {
    const contactId = workspaceSelected?.type === 'buyer' ? orderSelected.sellerId : orderSelected.buyerId;
    const contact = orderSelected ? contacts[contactId] : undefined;

    const childProps = {
      me,
      workspaceSelected,
      asSeller,
      history,
      location,
      contacts,
      BackHeader: (
        <S.BackContainer>
          <S.Back
            onClick={() => {
              setShowroomSelected(undefined);
              // Temporary solution before we move search state to redux
              sendSearch(searchState);
            }}
          >
            <S.ChevronIcon name="Back" />
            {__('Components.OrdersList.back_order')}
          </S.Back>
        </S.BackContainer>
      ),
      contact,
      match: {
        workspaceId,
      } as any,
      next: () => {
        setShowroomSelected(undefined);
      },
    };
    return (
      <S.CartContainer>
        <OrderDetails
          amSeller={workspaceSelected?.type === 'seller'}
          closeCart={() => {
            closeOrderSelected();
            // Temporary solution before we move search state to redux
            sendSearch(searchState);
          }}
          contact={contact}
          orderId={orderSelected && orderSelected.id}
          history={history}
          location={location}
          match={workspaceId as any}
          backLink={__('Components.Cart.back_orders')}
          navCloneAction={() => null}
          navShowroom={() =>
            setShowroomSelected(
              workspaceSelected?.type === 'seller' ? CHANNEL_SECTIONS.SHOWROOM_SELL : CHANNEL_SECTIONS.SHOWROOM_BUY,
            )
          }
          openedFrom={workspaceSelected?.type === 'seller' ? 'sales' : 'purchases'}
        />
        {showroomSelected ? (
          <S.ShowroomContainer>
            <S.ShowroomContainerRow>
              {showroomSelected === CHANNEL_SECTIONS.SHOWROOM_BUY ? (
                <ShowroomBuy {...childProps} />
              ) : (
                <ShowroomSell {...childProps} />
              )}
            </S.ShowroomContainerRow>
          </S.ShowroomContainer>
        ) : null}
      </S.CartContainer>
    );
  };

  /**
   * Show one order in the cart view
   */
  const openOrderSelected = React.useCallback((order: IOrder) => {
    setOrderSelected(order);
  }, []);

  /**
   * Close the selected order
   */
  const closeOrderSelected = React.useCallback(() => {
    setShowroomSelected(undefined);
    setOrderSelected(undefined);
  }, [sendSearch, searchState]);

  return (
    <Layout
      header={{
        show: true,
        title: __('Components.Header.Aggregation'),
        subtitle: '',
        tabSelected: asSeller ? 'sale' : 'purchase',
        breadcrumbs: [
          {
            label: asSeller ? __('Components.OrdersList.title_sales') : __('Components.OrdersList.title_purchases'),
            action: () =>
              history.push(
                getPath({
                  path: asSeller
                    ? workspaceId
                      ? ROUTE_PATHS.WORKSPACE_SALES
                      : ROUTE_PATHS.SALES
                    : workspaceId
                    ? ROUTE_PATHS.WORKSPACE_PURCHASES
                    : ROUTE_PATHS.PURCHASES,
                  workspaceId,
                }),
              ),
          },
          { label: __('Components.Header.Aggregation') },
        ],
      }}
    >
      <S.Container className="order-aggregation-container">
        {facets.byHash && Object.keys(facets.byHash).length ? (
          <>
            <S.HeaderRow>
              <S.FiltersTitle>
                {facets.byHash && Object.keys(facets.byHash).length}
                {__('Components.OrdersList.orders_found')}
                {filtersAppliedText}
              </S.FiltersTitle>
              {workspaceId ? (
                <Button
                  type="secondary"
                  iconUrl={constants.getLogoFromMime('application/pdf')}
                  loading={generatingPDF}
                  onClick={handleDownloadPDF}
                >
                  {__('Components.OrdersList.download_PDF')}
                </Button>
              ) : null}
            </S.HeaderRow>
            <AggregationTable
              asSeller={!!asSeller}
              myId={me.id}
              hourFormat={me.settings.hourFormat}
              searchState={searchState}
              orders={facets.byHash}
              productFields={productFields}
              contacts={contacts}
              onClick={orderHash =>
                dispatch(
                  orderActions.orderGetByHash(me.id!, orderHash, order => {
                    openOrderSelected(order);
                  }),
                )
              }
              weightUnit={workspaceSelected?.defaultWeightUnit}
            />
            <OrderAggregationFooter
              groups={facets}
              groupedBy="product"
              searchCurrency={searchState.currency}
              weightUnit={workspaceSelected?.defaultWeightUnit}
            />
          </>
        ) : (
          <S.CenterContainer>
            <EmptyListResource
              buttonAction={() => history.push(getPath({ path: ROUTE_PATHS.CHAT_LIST }))}
              buttonText={__('Components.OrdersList.EmptyResults.cta')}
              imageUrl={IMAGES.productsNoResult.replace('f_auto', 'c_scale,w_260')}
              showButton={true}
              text={__('Components.OrdersList.EmptyResults.title')}
              text2={__('Components.OrdersList.EmptyResults.description')}
            />
          </S.CenterContainer>
        )}
        {orderSelected ? renderNewCart() : null}
      </S.Container>
    </Layout>
  );
};
export default OrderAggregation;
